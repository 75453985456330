import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReportIssueDialog from './ReportIssueDialog';
import ThankYouDialog from './ThankYouDialog';
import { SimpleUser } from '../../services/usersService';


interface FooterProps {
  passedUser: SimpleUser;
}

const Footer: React.FC<FooterProps> = ({ passedUser }) => {
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [isThankYouDialogOpen, setIsThankYouDialogOpen] = useState(false);

  const handleReportSuccess = () => {
    setIsThankYouDialogOpen(true);
  };

  return (
    <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6">
      <p className="text-s text-agiGray dark:text-white">
        © 2024 AGI House. All rights reserved.
      </p>
      <nav className="sm:ml-auto flex gap-4 sm:gap-6">
        <button
          className="text-xs hover:underline underline-offset-4 text-agiLightGray"
          onClick={() => setIsReportDialogOpen(true)}
        >
          Report an Issue
          <span className="ml-4 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium border-blue-500 text-blue-500 border">
            Beta
          </span>
        </button>
      </nav>
      <ReportIssueDialog
        isOpen={isReportDialogOpen}
        onClose={() => setIsReportDialogOpen(false)}
        onSuccess={handleReportSuccess}
        passedUser = {passedUser}
      />
      <ThankYouDialog
        isOpen={isThankYouDialogOpen}
        onClose={() => setIsThankYouDialogOpen(false)}
      />
    </footer>
  );
};

export default Footer;
