import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDk38I6hdAovavqcV9Lnt17wr_IRNCCrcE",
  authDomain: "agihouse-app.firebaseapp.com",
  projectId: "agihouse-app",
  storageBucket: "agihouse-app.appspot.com",
  messagingSenderId: "576156791779",
  appId: "1:576156791779:web:52885ade22dff3159426d6",
  measurementId: "G-3RJFGKV736"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app); // Initialize Firestore
const auth = getAuth(app); // Initialize Firebase Auth
const storage = getStorage(app); // Add this line to initialize Firebase Storage

export { app, analytics, db, auth, storage };
