import axios from 'axios';
import { Project } from './projectsService';
import { Profile } from './searchService';

import { getActiveToken } from './authService';
import { API_URL } from './apiService';

const EVENT_BY_ID = 'event/';
const EVENTS_BY_USER_ID = 'event/';
const ALL_EVENTS = 'events/';
const CREATE_EVENT = 'event/new';
const UPDATE_EVENT = 'event/update';

const CREATE_APPLICATION = 'application/new';
const REMOVE_APPLICATION = 'application/remove';


export interface Sponsor {
  name: string;
  sponsorURL: string;
  description: string;
  imageSrc: string;
}

export interface Cohost {
  name: string;
  cohostURL: string;
  description: string;
  imageSrc: string;
}


  // todo: change to user_id

export interface EventPerson {
  name: string;
  userID: string; 
  title: string;
}

interface Application {
  user_id: string;
  status: string;
  answers: string[];
  request_time: string;
}

export interface Event {
  id: string;
  name: string;
  slug: string;
  description: string;
  date: string;
  timestamp: Date;
  location: string;
  imageSrc: string;
  status?: string;
  sponsors: Sponsor[];
  cohosts: Cohost[];
  judges: string[];
  judges_updated: EventPerson[];
  speakers_updated: EventPerson[];
  speakers: string[];
  projects: string[];
  questions: string[];
  access: string;
  type: string;
  applications: Application[];
  requireDefaultQuestions: boolean;
}

export interface EventAndProjects {
  event: Event;
  projects: Project[];
}

// fetchEventById
export interface EventData {
  response: EventAndProjects;
}

// fetchUserEventsById
export interface UserEventsData {
  response: Event[];
}

// fetchAllEvents
export interface AllEventsData {
  response: Event[];
}

export const fetchEventByIdOrSlug = async (event_id_or_slug: string, user_id?: string): Promise<EventData> => {
  const idToken = await getActiveToken();
  try {
    let targetURL = `${API_URL}${EVENT_BY_ID}${event_id_or_slug}`
    if (user_id) {
      targetURL = `${API_URL}${EVENT_BY_ID}${event_id_or_slug}?user_id=${user_id}`
    }
    const response = await axios.get<EventData>(targetURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`fetchEventById failed: ${error}`);
  }
};

// user id
export const fetchUserEventsById = async (user_id: string): Promise<UserEventsData> => {
  const idToken = await getActiveToken();
  try {
    const response = await axios.get<UserEventsData>(`${API_URL}user/${user_id}/${EVENTS_BY_USER_ID}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`fetchUserEventsById failed: ${error}`);
  }
};

export const fetchAllEvents = async (time_span?: string): Promise<AllEventsData> => {
  const idToken = await getActiveToken();
  // console.log('fetchAllEvents:', time_span);
  // console.log('idToken:', idToken);
  try {
    let targetURL = `${API_URL}events/all`
    if (time_span) {
      targetURL = `${API_URL}events/all?span=${time_span}`
    }
    const response = await axios.get<AllEventsData>(targetURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });

    // console.log('fetchAllEvents Response:', response);
    return response.data;
  } catch (error) {
    throw new Error(`fetchAllEvents failed: ${error}`);
  }
};

export const createEvent = async (new_event: Event): Promise<void> => {
  console.log('createEvent:', new_event);
  const idToken = await getActiveToken();
  try {
    const response = await axios.post(`${API_URL}${CREATE_EVENT}`, new_event, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
  } catch (error) {
    throw new Error(`createEvent failed: ${error}`);
  }
};

export const updateEvent = async (event: Event): Promise<void> => {
  console.log('updateEvent:', event);
  const idToken = await getActiveToken();

  try {
    const response = await axios.post(`${API_URL}${UPDATE_EVENT}`, event, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
  } catch (error) {
    throw new Error(`updateEvent failed: ${error}`);
  }
};

// Applications
export const createApplication = async (user_id: string, event_id: string, answers: string[], questions: string[]): Promise<void> => {
  console.log('createApplication:', { user_id, event_id, answers, questions });
  const idToken = await getActiveToken();

  try {
    const response = await axios.post(
      `${API_URL}${CREATE_APPLICATION}`,
      { user_id, event_id, answers, questions },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
      }
    );
    console.log('Application created successfully.');
  } catch (error) {
    throw new Error(`createApplication failed: ${error}`);
  }
};

export const removeApplication = async (user_id: string, event_id: string): Promise<void> => {
  console.log('removeApplication:', { user_id, event_id });
  const idToken = await getActiveToken();

  try {
    const response = await axios.post(
      `${API_URL}${REMOVE_APPLICATION}`,
      { user_id, event_id },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
      }
    );
    console.log('Application removed successfully.');
  } catch (error) {
    throw new Error(`removeApplication failed: ${error}`);
  }
};

interface ApplicationData {
  response: Profile[];
}

export const fetchEventApplications = async (event_slug: string): Promise<ApplicationData> => {
  const idToken = await getActiveToken();

  try {
    const response = await axios.get<ApplicationData>(`${API_URL}event/${event_slug}/applications`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`fetchUserEventsById failed: ${error}`);
  }
};

export const updateApplication = async (event_id: string, user_id: string, new_status: string): Promise<void> => {
  const idToken = await getActiveToken();

  console.log('updateApplication:', { event_id, user_id, new_status, idToken });
  try {
    const response = await axios.post<string>(`${API_URL}application/status?user_id=${user_id}&event_id=${event_id}&new_status=${new_status}`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
  } catch (error) {
    throw new Error(`updateApplication failed: ${error}`);
  }
};