import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWrapper from './App';
import MaintenanceScreen from './MaintenanceScreen';

const isInMaintenance = false;
const maintenanceMessage = "We are currently performing maintenance on the site. Please check back in 10 minutes.";

const root = ReactDOM.createRoot(document.getElementById('root'));

if (isInMaintenance) {
  // Show maintenance screen
  root.render(
    <MaintenanceScreen message={maintenanceMessage} />
  );
} else {
  // Show the app
  root.render(
    <AppWrapper />
  );
}

