import axios from 'axios';
import { getActiveToken } from './authService';

import { API_URL } from './apiService';


export const reportIssue = async (email: string, name: string, issue: string): Promise<void> => {
    // console.log('reportIssue():', email, name, issue);
    const idToken = await getActiveToken();
    try {
      const response = await axios.post<string>(`${API_URL}issues`, {email, name, issue}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
      });
      console.log('reportIssue response:', response);
    } catch (error) {
      throw new Error(`reportIssue failed: ${error}`);
    }
  }