// Updated to Mongo Database 7/9 7/10. TASK: updateProject, finish/FIX Card3, 
import React, { useEffect, useState } from 'react';
import { Button } from '../components/ui/button';
import EditProfileDialog from '../components/profile/EditProfileDialog';
import { EventCard } from '../components/EventCard';
import ProjectCard from '../components/ProjectCard3'; // 7/9
import ProjectDialog from '../components/profile/ProjectDialog';
import UserBasicInfo from '../components/profile/UserBasicInfo'; // Component
import TheHouse from '../img/TheHouse.png';
import { Event, fetchUserEventsById } from '../services/eventsService';
import { fetchUserProjectsById, Project, createProject, updateProject } from '../services/projectsService';
import { useAuth } from '../AuthContext';


const Profile: React.FC = () => {
  const { user: theUser } = useAuth();
  const [projects, setProjects] = useState<Project[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isEditingProject, setIsEditingProject] = useState(false);
  const [isAddingProject, setIsAddingProject] = useState(false);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [projectAddError, setProjectAddError] = useState<string | null>(null);

  // TODO: ideally make user_id a state so it's always in sync with theUser
  const user_id = theUser?.id;

  const fetchUserProjects = async () => {
    try {
      if (!user_id) return;
      const projectsData = await fetchUserProjectsById(user_id);
      setProjects(projectsData.response.map(project => ({ ...project, id: project.id })));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserEvents = async () => {
    try {
      if (!user_id) return;
      const UserEventsData = await fetchUserEventsById(user_id);
      setEvents(UserEventsData.response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchUserProjects(),
        fetchUserEvents()
      ]);
    };
    fetchData();
  }, [user_id, isEditingProfile]);

  useEffect(() => {
    if (isAddingProject === false) {
      setProjectAddError(null);
    }
  }, [isAddingProject])

  // Check if user is brand new and needs to fill out their profile
  useEffect(() => {
    if (theUser && (!theUser.name || !theUser.headline || !theUser.linkedInURL)) {
      setIsEditingProfile(true);
    }
  }, [theUser]);

  const handleSaveProject = async (project: Project) => {
    console.log('handleSaveProject:', project);
    if (isAddingProject) {
      await createProject(project);
    } else {
      await updateProject(project);
      setIsEditingProject(false);
    }
    fetchUserProjects();
  };

  return (
    <div className="flex min-h-screen flex-col">
      <main className="flex-1">
        {theUser && (
          <>
            <UserBasicInfo user={theUser} onEdit={() => setIsEditingProfile(true)} />
            <EditProfileDialog
              isOpen={isEditingProfile}
              onClose={() => setIsEditingProfile(false)}
              userInfo={theUser}
            />
          </>
        )}
        <section className="py-12">
          <div className="container mx-auto px-4 md:px-6">
            <div className="mx-auto max-w-3xl">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold text-gray-50">Projects</h2>
                <Button
                  variant='default'
                  size="sm"
                  onClick={() => {
                    setCurrentProject(null);
                    setIsAddingProject(true);
                  }}
                >
                  Add New Project
                </Button>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                {projects.map((project, index) => (
                  <ProjectCard
                    key={index}
                    project={project}
                    isOwner={true}
                    showEventLabel={true}
                    onEdit={() => {
                      console.log('Edit project:', project);
                      setCurrentProject(project);
                      setIsEditingProject(true);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
        <ProjectDialog
          isOpen={isAddingProject || isEditingProject}
          onClose={() => {
            setIsAddingProject(false);
            setIsEditingProject(false);
          }}
          project={currentProject}
          onSave={handleSaveProject}
          isNew={isAddingProject}
          events={events}
          errorMessage={projectAddError}
          passedUser={theUser}
        />
        <section className="pb-12">
          <div className="container mx-auto px-4 md:px-6">
            <div className="mx-auto max-w-3xl">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold text-gray-50">Events</h2>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                {events.length > 0 ? (
                  events.map((event, index) => (
                    <EventCard
                      key={index}
                      imageSrc={event.imageSrc || TheHouse}
                      title={event.name}
                      description={event.description}
                      date={event.date}
                      timestamp={event.timestamp}
                      eventLink={`/events/${event.slug}`}
                      status={event.status}
                      showImage={true}
                    />
                  ))
                ) : (
                  <div className="col-span-full text-center text-agiLightGray">
                    You haven't joined any events.
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Profile;
