import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../lib/firebaseConfig';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useAuth } from '../AuthContext';
import { fetchUserByIdToken } from '../services/authService';

function ConfirmSignInPage() {
    const navigate = useNavigate();
    const { setUser, user } = useAuth();

    useEffect(() => {
        const confirmSignIn = async () => {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                let email = window.localStorage.getItem('emailForSignIn');
                if (!email) {
                    email = window.prompt('Please provide your email for confirmation');
                }
                if (email) {

                    try {
                        await signInWithEmailLink(auth, email, window.location.href);
                    } catch (error) {
                        alert('Error signing in with email link. Are you sure you typed your email correctly?')
                        navigate('/login');
                    }
                    console.log('Successfully signed in with email link');
                    console.log(auth.currentUser);
                    window.localStorage.removeItem('emailForSignIn');
                    try {
                        const fetchedUser = await fetchUserByIdToken();
                        if (fetchedUser === null) {
                            alert('Error signing in with email link. User = null. Please report this error to henry@agihouse.org.');
                            // Log out the user and navigate to the login page
                            await auth.signOut();
                            setUser(null);
                            navigate('/login');
                        } else {
                            // SUCCESS PATH
                            setUser(fetchedUser);
                            navigate('/profile', { replace: true });
                        }
                    } catch (error) {
                        alert('Error fetching or setting up user. Please report this error to henry@agihouse.org.')
                        await auth.signOut();
                        setUser(null);
                        navigate('/login');
                    }
                } else {
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        };

        confirmSignIn();
    }, []);

    return (
        <div className="flex items-center justify-center h-screen bg-agiBlack">
            <div className="text-center">
                <h1 className="text-xl font-semibold text-white">Confirming your sign-in...</h1>
                <div className="mt-4 inline-block w-8 h-8 border-4 rounded-full animate-spin"
                    style={{
                        borderColor: 'rgba(156, 163, 175, 0.5)', // A light gray border color
                        borderTopColor: 'rgba(59, 130, 246)', // A distinct blue top border color
                    }}>
                </div>
            </div>
        </div>
    );
}

export default ConfirmSignInPage;
