import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import CreateEventDialog from './CreateEventDialog'; // for editing event
import TalentProfile from './TalentProfile2';
import CompanyProfileSheet from './CompanyProfileSheet';
import { Profile, fetchCompanyById, fetchTalentById } from '../../services/searchService';
import { fetchEventApplications, fetchEventByIdOrSlug, Event, updateApplication } from '../../services/eventsService';
import { SimpleUser } from '../../services/usersService';

import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { Input } from '../ui/input';

interface EventManagementProps {
  slug: string;
  passedUser: SimpleUser;
}

const EventManagement: React.FC<EventManagementProps> = ({ slug, passedUser }) => {

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false); // For editing the event
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false); // For export dialog
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedUserProfile, setSelectedUserProfile] = useState<Profile>(null);
  const [reviewedHoveredIndex, setReviewedHoveredIndex] = useState(null);
  const [pendingHoveredIndex, setPendingHoveredIndex] = useState(null);
  const [pendingRequests, setPendingRequests] = useState<Profile[]>([]);
  const [reviewedRequests, setReviewedRequests] = useState<Profile[]>([]);
  const [event, setEvent] = useState<Event | null>(null);
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [approvedGuests, setApprovedGuests] = useState(0);
  const [noApplications, setNoApplications] = useState(false);
  // For extra profile (talent or company) view
  const [extraProfileId, setExtraProfileId] = useState("");
  const [extraProfile, setExtraProfile] = useState(null);
  const [extraProfileType, setExtraProfileType] = useState(null);
  // bandaid fix :(
  const [extraProfileReady, setExtraProfileReady] = useState(false);

  const [selectedStatuses, setSelectedStatuses] = useState({
    accepted: true,
    declined: false,
    pending: false,
  });

  const [searchString, setSearchString] = useState("");

  const selectExtra = (id, extraType) => {
    setExtraProfileReady(false);
    console.log("selectExtra:", id, extraType);
    setExtraProfileType(extraType);
    console.log("extraType updated. Old id:", extraProfileId, "new id:", id);
    setExtraProfileId(id);
  }

  const loadExtraProfile = async () => {
    // Load in extra profile if exists
    if (extraProfileId !== "") {
      if (extraProfileType === 'Company') {
        console.log("loading extra company profile...");
        const extraResponse = await fetchCompanyById(extraProfileId);
        console.log("extraResponse:", extraResponse);
        setExtraProfile(extraResponse.response)
      } else if (extraProfileType === 'Talent') {
        console.log("loading extra talent profile...");
        const extraResponse = await fetchTalentById(extraProfileId);
        console.log("extraResponse:", extraResponse);
        setExtraProfile(extraResponse.response)
      }
    }
  }

  useEffect(() => {
    console.log("extraProfileId:", extraProfileId);
    if (extraProfileId === "") {
      setExtraProfile(null)
      setExtraProfileType(null)
    } else {
      console.log("loading extra profile...", extraProfileId)
      loadExtraProfile()
      // Bandaid fix for now
      // Issue occurs when the extra profile is not loaded in time when going from a company to a talent (all within the extra inner view)
      setTimeout(() => {
        setExtraProfileReady(true);
      }, 500);
    }
  }, [extraProfileId]);

  // for create/edit event dialog
  const handleClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleProfileHover = (profile, index, status) => {
    if (status === 'reviewed') {
      setReviewedHoveredIndex(index);
      setPendingHoveredIndex(null);
    } else {
      setPendingHoveredIndex(index);
      setReviewedHoveredIndex(null);
    }
    setSelectedUserProfile(profile);
  };

  useEffect(() => {
    const fetchEvent = async (slug: string) => {
      const fetchedEvent = await fetchEventByIdOrSlug(slug);
      // console.log('Fetched Event:', fetchedEvent.response);
      setEvent(fetchedEvent.response.event);
      if (fetchedEvent.response.event.applications && fetchedEvent.response.event.applications.length > 0) {
        fetchEventApps(slug);
      } else {
        console.log('No applications found for event (either no apps or legacy event):', slug);
        setNoApplications(true);
      }
    };
    const fetchEventApps = async (slug: string) => {
      console.log('Fetching event applications for:', slug);
      const eventApplications = await fetchEventApplications(slug);
      console.log('Event applications:', eventApplications);

      setProfiles(eventApplications.response);
      setPendingRequests(eventApplications.response.filter(profile => profile.eventInfo.status === 'pending'));
      setReviewedRequests(eventApplications.response.filter(profile => profile.eventInfo.status !== 'pending'));
      setTotalApplicants(eventApplications.response.length);
      setApprovedGuests(eventApplications.response.filter(profile => profile.eventInfo.status === 'approved').length);

    }
    // Prob just want one API call here in the future.
    fetchEvent(slug);
  }, [slug]);


  const handleRequestAction = async (profile, action) => {
    if (profile.eventInfo.status === action) {
      return;
    }
    try {
      console.log(`${action.charAt(0).toUpperCase() + action.slice(1)}ing request:`, profile);
      updateApplication(event.id, profile.user.id, action);
      // Update the profile's status locally
      const updatedProfile = { ...profile, eventInfo: { ...profile.eventInfo, status: action } };
      // Refilter pending and reviewed requests
      setPendingRequests(pendingRequests.filter(r => r.user.id !== profile.user.id));
      setReviewedRequests(prev => {
        const updatedReviewed = prev.filter(r => r.user.id !== profile.user.id);
        if (action !== 'pending') {
          updatedReviewed.push(updatedProfile);
        }
        return updatedReviewed;
      });
      if (action === 'approved') {
        setApprovedGuests(prev => prev + 1);
      } else if (action === 'declined') {
        setApprovedGuests(prev => prev - 1);
      }
    } catch (error) {
      console.error(`Error ${action}ing request:`, error);
    }
  };
  const handleApproveRequest = (profile) => handleRequestAction(profile, 'approved');
  const handleDeclineRequest = (profile) => handleRequestAction(profile, 'declined');

  const cleanUpURL = (url) => {
    return "https://" + url.replace(/^(https?:\/\/)?(www\.)?/, '');
  };

  const exportToCsv = (filename: string, data: any[]) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    console.log("export ready.")
    saveAs(blob, filename);
  };

  const handleCheckboxChange = (status) => {
    setSelectedStatuses({
      ...selectedStatuses,
      [status]: !selectedStatuses[status],
    });
  };

  const handleExport = () => {
    let selectedRequests = [];
  
    // Filter based on selected statuses
    if (selectedStatuses.accepted) {
      selectedRequests = selectedRequests.concat(reviewedRequests.filter((req) => req.eventInfo.status === 'approved'));
    }
    if (selectedStatuses.declined) {
      selectedRequests = selectedRequests.concat(reviewedRequests.filter((req) => req.eventInfo.status === 'declined'));
    }
    if (selectedStatuses.pending) {
      selectedRequests = selectedRequests.concat(pendingRequests.filter((req) => req.eventInfo.status === 'pending'));
    }
  
    // Sort by accepted, declined, then pending
    selectedRequests.sort((a, b) => {
      if (a.eventInfo.status === 'approved') return -1;
      if (b.eventInfo.status === 'approved') return 1;
      if (a.eventInfo.status === 'declined') return -1;
      if (b.eventInfo.status === 'declined') return 1;
      return 0;
    });
  
    // Get all unique questions across all selected requests
    const uniqueQuestions = Array.from(new Set(
      selectedRequests.flatMap(req => req.eventInfo.questions)
    ));
  
    // Prepare data for CSV export
    const csvData = selectedRequests.map((profile) => {
      // Initialize the row with user info
      const row = {
        name: profile.user.name,
        email: profile.user.email,
        event_status: `${
          profile.eventInfo.status === 'approved' ? '🟢' :
          profile.eventInfo.status === 'declined' ? '🔴' :
          profile.eventInfo.status === 'pending' ? '🟡' : ''
        } ${profile.eventInfo.status}`,
        headline: profile.user.headline,
        linkedin: profile.user.linkedInURL,
        twitter: profile.user.twitterURL,
        github: profile.user.githubURL,
      };
  
      // Map answers to the correct questions
      uniqueQuestions.forEach((question, index) => {
        const questionIndex = profile.eventInfo.questions.indexOf(question);
        row[question] = questionIndex !== -1 ? profile.eventInfo.answers[questionIndex] : '';
      });
  
      return row;
    });
  
    // Create the CSV file name
    const selectedStatusNames = [];
    if (selectedStatuses.accepted) selectedStatusNames.push('approved');
    if (selectedStatuses.declined) selectedStatusNames.push('declined');
    if (selectedStatuses.pending) selectedStatusNames.push('pending');
  
    const statusPart = selectedStatusNames.join('_');
    let eventNamePart = event.name.substring(0, 10).replace(/ /g, '_').toLowerCase();
    if (eventNamePart.endsWith('_')) {
      eventNamePart = eventNamePart.slice(0, -1);
    }
    const fileName = `${eventNamePart}_${statusPart}.csv`;
  
    // Export to CSV
    exportToCsv(fileName, csvData);
    setIsExportDialogOpen(false);
  };
  
  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setExtraProfileId("")
      }
      if (event.key === '!') {
        if (extraProfileId === '' && selectedUserProfile?.user.linkedInURL) {
          window.open(selectedUserProfile.user.linkedInURL, '_blank');
        }
        if (extraProfileId !== '') {
          if (extraProfileType === "Talent") {
            // console.log("extraProfile:", extraProfile.user.linkedInURL);
            window.open(cleanUpURL(extraProfile.user.linkedInURL), '_blank');
          }
          if (extraProfileType === "Company") {
            window.open(cleanUpURL(extraProfile.linkedInURL), '_blank');
          }
        }
      }
      if (event.key === '@') {
        if (extraProfileId === '' && selectedUserProfile?.user.githubURL) {
          window.open(cleanUpURL(selectedUserProfile.user.githubURL), '_blank');
        }
        if (extraProfileId !== '') {
          if (extraProfileType === "Talent") {
            window.open(cleanUpURL(extraProfile.user.githubURL), '_blank');
          }
          if (extraProfileType === "Company") {
            window.open(cleanUpURL(extraProfile.website), '_blank');
          }
        }
      }
      if (event.key === '#') {
        if (extraProfileId === '' && selectedUserProfile?.user.twitterURL) {
          window.open(selectedUserProfile.user.twitterURL, '_blank');
        }
        if (extraProfileId !== '') {
          console.log("extraProfile:", extraProfile)
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [selectedUserProfile, extraProfile]);

  return (
    <div className="border-y border-agiMediumGray flex sm:flex-row flex-col items-center justify-center w-full max-w-[3000px] text-white" style={{ height: `calc(100vh - 128px)` }}>
      {/* EventManagement Left */}
      <div className='sm:w-1/4 w-full sm:h-full h-[50vh] sm:border-r sm:border-b-0 border-b border-agiGray'>
        <div className=' h-full overflow-hidden'>
          <div className="flex flex-col gap-2 justify-start items-center h-full overflow-scroll px-4">
            {event && (
              <h3 className="text-2xl font-bold text-white w-full text-center mt-4">{event.name}</h3>
            )}
            {event && noApplications === true && (
              <p className="text-lg font-bold text-white w-full text-center">No applications or legacy event.</p>
            )}
            <div className="text-lg font-medium text-agiLightGray w-full text-center mb-2">Applications: {totalApplicants} | Approved: {approvedGuests}</div>
            <div className='flex gap-2'>
              <Link to={`/events/${slug}`}>
                <Button
                  variant="outline"
                  size="sm"
                >
                  View
                </Button>
              </Link>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsEditDialogOpen(true)}
              >
                Edit
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsExportDialogOpen(true)}
                disabled={totalApplicants === 0}
              >
                Export
              </Button>
            </div>

{/* Search Field */}
<Input
className='mt-4'
                    id="event-name"
                    placeholder="Search filter"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)
                    }
                  />

<p className='font-medium text-white text-lg mt-8'>Pending Requests</p>
{pendingRequests
.filter((profile) => {
  if (!profile.user.headline || !profile.user.name || !profile.user.email) {
    console.log('Profile missing headline, name, or email:', profile);
  }
  return (
    (profile.user.name && profile.user.name.toLowerCase().includes(searchString.toLowerCase())) ||
    (profile.user.email && profile.user.email.toLowerCase().includes(searchString.toLowerCase())) ||
    (profile.user.headline && profile.user.headline.toLowerCase().includes(searchString.toLowerCase()))
  );
})


  .map((profile, index) => (
    <div
      key={index}
      onMouseEnter={() => handleProfileHover(profile, index, 'pending')}
      className={`flex items-center justify-between px-4 py-4 w-full rounded-lg border border-agiDarkGray text-white ${pendingHoveredIndex === index ? 'border-agiDarkGray bg-agiDarkGray' : ''}`}
    >
      <div className='flex items-center justify-between gap-4 w-full'>
        {profile.user.name}
        <div className='flex'>
          <div className='group p-2 rounded-full bg-transparent hover:bg-agiGray hover:cursor-pointer' onClick={() => handleApproveRequest(profile)}>
            <FaCheckCircle className="text-md text-agiGray group-hover:text-agiLightGray" />
          </div>
          <div className='group p-2 rounded-full bg-transparent hover:bg-agiGray hover:cursor-pointer' onClick={() => handleDeclineRequest(profile)}>
            <FaMinusCircle className="text-md text-agiGray group-hover:text-agiLightGray" />
          </div>
        </div>
      </div>
    </div>
  ))}

<p className='font-medium text-white text-lg mt-8'>Reviewed Requests</p>
{reviewedRequests
.filter((profile) => {
  if (!profile.user.headline || !profile.user.name || !profile.user.email) {
    console.log('Profile missing headline, name, or email:', profile);
  }
  return (
    (profile.user.name && profile.user.name.toLowerCase().includes(searchString.toLowerCase())) ||
    (profile.user.email && profile.user.email.toLowerCase().includes(searchString.toLowerCase())) ||
    (profile.user.headline && profile.user.headline.toLowerCase().includes(searchString.toLowerCase()))
  );
})

  .map((profile, index) => (
    <div
      key={index}
      onMouseEnter={() => handleProfileHover(profile, index, 'reviewed')}
      className={`flex items-center justify-between px-4 py-4 w-full rounded-lg border border-agiDarkGray text-white ${reviewedHoveredIndex === index ? 'border-agiDarkGray bg-agiDarkGray' : ''}`}
    >
      <div className='flex items-center justify-between gap-4 w-full'>
        {profile.user.name}
        <div className='flex'>
          <div className='group p-2 rounded-full bg-transparent hover:bg-agiGray hover:cursor-pointer' onClick={() => handleApproveRequest(profile)}>
            <FaCheckCircle
              className={` ${profile.eventInfo.status === 'approved' ? 'text-green-500' : 'text-agiGray  group-hover:text-agiLightGray'}`} // Green if approved
            />
          </div>
          <div className='group p-2 rounded-full bg-transparent hover:bg-agiGray hover:cursor-pointer' onClick={() => handleDeclineRequest(profile)}>
            <FaMinusCircle
              className={` ${profile.eventInfo.status === 'approved' ? 'text-agiGray group-hover:text-agiLightGray' : 'text-red-500 '}`} // Red if not approved
            />
          </div>
        </div>
      </div>
    </div>
  ))}

            <div className='mb-[500px] '></div>
          </div>
        </div>
      </div>
      {/* Profile Right */}
      <div className='relative w-3/4 h-full overflow-scroll'>
        {/* Top layer */}
        {/* Layer 1 (top extra) */}
        {extraProfile != null && (
          <div className='absolute top-0 left-0 w-full h-full z-20 bg-[#00000090] p-4'>
            <div className='h-full border border-agiMediumGray rounded-lg flex flex-col overflow-scroll bg-agiBlack'>
              {/* FIX / CHECK overflow and scrolling */}
              <div className='relative px-12 flex w-full justify-end z-30'>
                <button className="flex gap-2 absolute rounded-lg justify-center items-center text-agiLightGray mt-4 hover:text-white" onClick={() => setExtraProfileId("")}>
                  Close
                  <div className='flex justify-center items-center text-xs font-bold pl-3 pr-3 h-6 border-[1.5px] border-agiMediumGray text-agiGray5 rounded-md font-DMMono'>
                    ESC
                  </div>
                </button>
              </div>
              {extraProfileType === 'Talent' ? (
                <div className=' flex justify-center items-center'>
                  {extraProfileReady ? (
                    <TalentProfile
                      profile={extraProfile}
                      onExtraSelect={selectExtra}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              ) : (
                extraProfileType === 'Company' && (

                  <div className=' flex justify-center items-center'>
                    {extraProfileReady ? (
                      <CompanyProfileSheet company={extraProfile}
                        onExtraSelect={selectExtra}
                        onTrackedToggle={() => { }}
                      />
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {/* Render Bottom Layer Talent Profile */}
        {selectedUserProfile != null && (
          <TalentProfile
            profile={selectedUserProfile}
            onExtraSelect={selectExtra}
          />
        )}
      </div>
      {/* For editing event */}
      <CreateEventDialog
        isOpen={isEditDialogOpen}
        onClose={handleClose}
        event={event}
      />

<Dialog open={isExportDialogOpen} onOpenChange={() => {
      setIsExportDialogOpen(false);
          }}>
      <DialogContent className="md:max-w-lg">
        <DialogHeader>
          <DialogTitle>Export CSV</DialogTitle>
        </DialogHeader>
        <div className="grid gap-6">
        <h2 className="text-lg font-bold">Select statuses to export</h2>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={selectedStatuses.accepted}
                onChange={() => handleCheckboxChange('accepted')}
                className="mr-2"
              />
              <p>Approved <span className='text-agiLightGray'>({reviewedRequests.filter(profile => profile.eventInfo.status === 'approved').length})</span></p>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={selectedStatuses.pending}
                onChange={() => handleCheckboxChange('pending')}
                className="mr-2"
              />
              <p>Pending <span className='text-agiLightGray'>({pendingRequests.length})</span></p>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={selectedStatuses.declined}
                onChange={() => handleCheckboxChange('declined')}
                className="mr-2"
              />
              <p>Declined <span className='text-agiLightGray'>({reviewedRequests.filter(profile => profile.eventInfo.status === 'declined').length})</span></p>
            </label>
            <div className="flex gap-2">
              <Button variant="outline" size="sm" className="w-full" onClick={() => setIsExportDialogOpen(false)}>
                Cancel
              </Button>
              <Button 
  variant="white" 
  size="sm" 
  className="w-full" 
  onClick={handleExport} 
  disabled={!(selectedStatuses.accepted || selectedStatuses.declined || selectedStatuses.pending)} // Inline check for any selected status
>
  Export
</Button>

            </div>
        </div>
      </DialogContent>
    </Dialog>

    </div>
  );
};

export default EventManagement;
