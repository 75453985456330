import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircleCheckBig } from 'lucide-react';
// import { SimpleUser, fetchSimpleUserById } from '../services/usersService';
import { sendSignInLinkToEmail } from "firebase/auth";
import { auth } from '../lib/firebaseConfig';

function EmailLinkLoginPage() {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       setCurrentUser(user);
  //       // Redirect after login if there is a stored redirect URL
  //       const redirectUrl = window.localStorage.getItem('redirectUrl');
  //       if (redirectUrl) {
  //         window.localStorage.removeItem('redirectUrl');
  //         navigate(redirectUrl);
  //       }
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [navigate]);


  useEffect(() => {
    if (auth.currentUser) {
      // If the user is already signed in, redirect to the profile page
      // alert('You are already signed in.');
      navigate('/profile');
    }
  }, []);

  const handleSendEmailLink = async () => {
    const redirectUrl = `${window.location.origin}${location.pathname}`;
    window.localStorage.setItem('redirectUrl', redirectUrl);

    const actionCodeSettings = {
      url: `${process.env.REACT_APP_CONFIRM_URL}?redirect=${encodeURIComponent(redirectUrl)}`,
      handleCodeInApp: true
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email); // Store the email locally to complete the sign-in process later
      setSent(true);
    } catch (error) {
      console.error("Error sending email link:", error);
    }
  };

  return (
    <div className='max-w-5xl mx-auto my-[100px] md:p-12 p-2'> 
    <div className="relative max-w-4xl mx-auto rounded-lg grid md:grid-cols-1 overflow-hidden">
        <div className='absolute h-6 w-6  top-0 left-0 border-agiGray border-l border-t rounded-tl-lg'></div>
        <div className='absolute h-6 w-6  top-0 right-0 border-agiGray border-r border-t rounded-tr-lg'></div>
        <div className='absolute h-6 w-6  bottom-0 left-0 border-agiGray border-l border-b rounded-bl-lg'></div>
        <div className='absolute h-6 w-6  bottom-0 right-0 border-agiGray border-r border-b rounded-br-lg'></div>
      <div className='flex justify-center items-center flex-col py-24'>
      <h1 className="text-3xl font-bold text-center mb-6 text-white">Sign In with Email Link</h1>
      {sent ? (
        <div className='flex items-center flex-col gap-4'>
        <div className=' gap-4 flex text-green-500 text-center p-4 px-6 rounded-lg  border border-green-500'>
          <CircleCheckBig></CircleCheckBig>
        <p className="">Check your email for your sign in link.</p>
      </div>
      <div className='flex gap-4 w-full justify-center text-agiLightGray'>
      <p className=''>{email}</p>
      <button className='underline' onClick={() => setSent(false)}>try again</button>
      </div>
      </div>
      ) : (
        <form onSubmit={(e) => {
          if (email === '') {
            alert('Please enter your email address');
            return;
          } else {
            e.preventDefault();
            handleSendEmailLink();
          }
        }} className="space-y-4 max-w-xl w-full sm:px-24 px-12">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-agiLightGray">Email:</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className=" placeholder:text-agiGray bg-agiBlack mt-1 block w-full p-4 px-6 border border-agiGray rounded-lg focus:outline-none focus:border-[#007FFF] text-white"
            />
          </div>
          <button type="submit" className="transition-all hover:scale-[103%] w-full bg-plurple-gradient flex justify-center p-4 px-6 rounded-md shadow-sm text-md font-bold text-white ">
            Send Sign In Link
          </button>
        </form>
      )}
      </div>
    </div>
    </div>
  );
}

export default EmailLinkLoginPage;
