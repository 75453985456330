import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import React, { useEffect, useState } from 'react';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../ui/select';
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { Event } from '../../services/eventsService';
import { Project, TeamMember, ProjectEvent} from '../../services/projectsService';
import { SimpleUser } from '../../services/usersService';
import { fetchUserByEmail } from "../../services/projectsService";

// TODO: switch over to AuthContext from passedUser
interface ProjectDialogProps {
  isOpen: boolean;
  onClose: () => void;
  project?: Project;
  onSave: (project: Project) => void;
  isNew?: boolean;
  events: Event[];
  errorMessage?:string;
  passedUser?: SimpleUser;
}

const ProjectDialog: React.FC<ProjectDialogProps> = ({ isOpen, onClose, project, onSave, isNew = false, events, errorMessage = null, passedUser }) => {
  const initialProjectState = {
    event: {event_id: '', event_name: ''},
    id: '',
    name: '',
    description: '',
    imageSrc: '',
    techStack: '',
    teamMembers: passedUser? [{member_id: passedUser.id, member_name: passedUser.name}] : [],
    awardName: '',
    awardWeight: 0,
    slidesURL: '',
    githubRepoURL: '',
    mentors: [],
  };

  const [projectState, setProjectState] = useState<Project>(initialProjectState);
  const [selectedEvent, setSelectedEvent] = useState<ProjectEvent>();
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<TeamMember[]>([]);
  const [theUser, setTheUser] = useState<SimpleUser | null>(passedUser);
  const [newEmail, setNewEmail] = useState<string>('');
  
  useEffect(() => {
    if (project) {
      setProjectState(project);
      setSelectedEvent(project.event);
      setSelectedTeamMembers(project.teamMembers);
    } else if (isNew) {
      setProjectState(initialProjectState);
      setSelectedEvent(null);
      setSelectedTeamMembers(initialProjectState.teamMembers);
    }
  }, [project, isNew, isOpen]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setProjectState((prevProject) => ({
      ...prevProject,
      [name]: value,
    }));
  };

  const handleAddNewMember = async (email) => {
    setNewEmail('');
    const response = await fetchUserByEmail(email);
  
    const memberExists = selectedTeamMembers.some(member => member.member_id === response.id);
  
    if (memberExists) {
      alert('Member already exists in the list');
    } else {
      const newMember = {
        member_id: response.id,
        member_name: response.name,
      };
      setSelectedTeamMembers([...selectedTeamMembers, newMember]);
      // console.log('newMember:', newMember);
      // console.log('oldMembers:', selectedTeamMembers);
    }
  };
  
  const handleSave = () => {
    if (!selectedEvent) {
      alert('Please select an event for your project.');
      return;
    }
    onSave({
      ...projectState,
      event: selectedEvent,
      teamMembers: selectedTeamMembers,
    });
    cleanup();
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const cleanup = () => {
    setProjectState(initialProjectState);
    setSelectedEvent(null);
    setSelectedTeamMembers(null);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="md:max-w-lg">
        <DialogHeader>
          <DialogTitle>{isNew ? "Add New Project" : "Edit Project"}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-6">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <Label htmlFor="name">Project Title</Label>
              <Input
                name="name"
                id="name"
                value={projectState.name}
                onChange={handleChange}
                placeholder="Enter project title"
              />
            </div>
            <div>
              <Label htmlFor="description">Description</Label>
              <Textarea
                name="description"
                id="description"
                value={projectState.description}
                onChange={handleChange}
                placeholder="Enter project description"
              />
            </div>
            <div>
              <Label htmlFor="techStack">Tech Stack</Label>
              <Input
                name="techStack"
                id="techStack"
                value={projectState.techStack}
                onChange={handleChange}
                placeholder="Enter tech stack (comma separated)"
              />
            </div>
            <div>
              <Label htmlFor="slidesURL">Slides Link</Label>
              <Input
                name="slidesURL"
                id="slidesURL"
                value={projectState.slidesURL}
                onChange={handleChange}
                placeholder="Enter link to slides"
              />
            </div>
            <div>
              <Label htmlFor="githubRepoURL">Github Repo Link</Label>
              <Input
                name="githubRepoURL"
                id="githubRepoURL"
                value={projectState.githubRepoURL}
                onChange={handleChange}
                placeholder="Enter link to Github repo"
              />
            </div>
            <div className="">
  <Label htmlFor="teamMembers">Team Members</Label>
  <div className="w-full rounded-md border-agiGray border py-2">
  {selectedTeamMembers?.map((member) => (
  <div key={member.member_id} className="flex items-center justify-between px-4 py-2">
    <span>{member.member_name} {member.member_id === theUser.id ? '(You)' : ''}</span>
    {theUser && member.member_id !== theUser.id && (
      <Button
        variant="outline"
        onClick={() => setSelectedTeamMembers(selectedTeamMembers.filter((m) => m.member_id !== member.member_id))}
      >
        Remove Member
      </Button>
    )}
  </div>
))}


    <div className=" bg-agiGray h-[1px] mx-4 my-2"></div>
    <div className="flex items-center justify-between px-4 py-2 gap-4">
      <Input
        name="newMemberEmail"
        id="newMemberEmail"
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        placeholder="Add member via email. (test@agihouse.org)"
      />
      <Button
        variant="default"
        onClick={() => handleAddNewMember(newEmail)}
      >
        Add Member
      </Button>
    </div>
  </div>
</div>



            {isNew && (
              <div>
                <Label htmlFor="event">Event</Label>
                <Select
                  name="event"
                  onValueChange={(value) => {
                    const event = events.find(event => event.id === value);
                    setSelectedEvent({event_id: event.id, event_name: event.name});
                    console.log('selectedEvent:', {event_id: event.id, event_name: event.name})
                  }}
                  // value={selectedEvent?.event_name}
                  value={selectedEvent?.event_id}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select event" />
                  </SelectTrigger>
                  <SelectContent>
                    {events.length > 0 ? (
                      events.map(event => (
                        <SelectItem key={event.id} value={event.id}>
                          <p className="max-w-[300px] py-2">
                          {event.name}<span className="text-agiLightGray"> · {event.date}</span>
                          </p>
                        </SelectItem>
                      ))
                    ) : (
                      <SelectGroup>
                        <SelectLabel>No options.</SelectLabel>
                      </SelectGroup>
                    )}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
        </div>
        <DialogFooter>
          {errorMessage}
          <Button variant="white" type="button" onClick={handleSave}>
            {isNew ? "Add Project" : "Save Changes"}
          </Button>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDialog;
