import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import React, { useState } from 'react';
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { SimpleUser } from '@/services/usersService';
import { reportIssue } from "../../services/issuesService";

interface ReportIssueDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  passedUser: SimpleUser;
}

const ReportIssueDialog: React.FC<ReportIssueDialogProps> = ({ isOpen, onClose, onSuccess, passedUser }) => {

  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [contactInfo, setContactInfo] = useState('');

  const handleSubmit = async () => {
    try {
      console.log("Submitting issue...");
      await reportIssue(contactInfo, name, description);
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error reporting issue: ", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="md:max-w-lg">
        <DialogHeader>
          <DialogTitle>Report an Issue</DialogTitle>
        </DialogHeader>
        <div className="grid gap-6">
          <div className="grid grid-cols-1 gap-4">

            <div>
              <Label htmlFor="name">Name</Label>
              <Input
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
              />
            </div>
            <div>
              <Label htmlFor="contactInfo">Contact Info</Label>
              <Input
                name="contactInfo"
                id="contactInfo"
                value={contactInfo}
                onChange={(e) => setContactInfo(e.target.value)}
                placeholder="Enter your contact info"
              />
            </div>
            <div>
              <Label htmlFor="description">Description</Label>
              <Textarea
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe the issue"
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button type="button" onClick={handleSubmit}>Submit</Button>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReportIssueDialog;
