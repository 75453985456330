import axios from 'axios';
import { SimpleUser } from './usersService';
import { getActiveToken } from './authService';

import { API_URL } from './apiService';
const PROJECTS_BY_USER_ID = 'projects';
const UPDATE_PROJECT = 'projects/update';
const CREATE_PROJECT = 'projects/create';

export interface ProjectEvent {
  event_id: string;
  event_name: string;
}

export interface TeamMember {
  member_id: string;
  member_name: string;
}

export interface Project {
  id: string;
  name: string;
  description: string;
  techStack: string;
  teamMembers: TeamMember[];
  imageSrc: string;
  event: ProjectEvent;
  awardName: string;
  awardWeight: number;
  slidesURL: string;
  githubRepoURL: string;
  mentors: string[];
}

interface UserProjectsData {
  response: Project[];
}

export const fetchUserProjectsById = async (user_id: string): Promise<UserProjectsData> => {
  const idToken = await getActiveToken();
  try {
    const response = await axios.get<UserProjectsData>(`${API_URL}user/${user_id}/${PROJECTS_BY_USER_ID}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    // console.log('fetchUserProjectsById:', response);
    return response.data;
  } catch (error) {
    throw new Error(`fetchUserProjectsById failed: ${error}`);
  }
};

export const createProject = async (new_project: Project): Promise<void> => {
  const idToken = await getActiveToken();
  // console.log('createProject:', new_project);
  try {
    const response = await axios.post(`${API_URL}${CREATE_PROJECT}`, new_project, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
  } catch (error) {
    throw new Error(`createProject failed: ${error}`);
  }
};

export const updateProject = async (updated_project: Project): Promise<void> => {
  const idToken = await getActiveToken();
  // console.log('updatedProject:', updated_project);
  try {
    const response = await axios.post(`${API_URL}${UPDATE_PROJECT}`, updated_project, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
  } catch (error) {
    throw new Error(`updatedProject failed: ${error}`);
  }
};

export const fetchUserByEmail = async (email: string): Promise<SimpleUser> => {
  const idToken = await getActiveToken();
  // console.log('fetchUserByEmail:', email);
  try {
    const response = await axios.get<SimpleUser>(`${API_URL}user/email?email=${email}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    console.log('fetchUserByEmail Response:', response);
    return response.data;
  } catch (error) {
    throw new Error(`fetchUserByEmail failed: ${error}`);
  }
};
