import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Button } from "../ui/button";
import React from 'react';
import { SimpleUser } from '@/services/usersService';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../lib/firebaseConfig';
import { useAuth } from '../../AuthContext';

interface UserBasicInfoProps {
  user: SimpleUser;
  onEdit: () => void;
}

const UserBasicInfo: React.FC<UserBasicInfoProps> = ({ user, onEdit }) => {
  const navigate = useNavigate();
  const { setUser, setLoading } = useAuth();

  const handleLogout = async () => {
    setLoading(true);
    try {
      await auth.signOut();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setLoading(false);
    }
  };

  const getSocialHandle = (url: string, pattern: RegExp) => {
    if (!url) return '';
    return `@${url.replace(pattern, '')}`;
  };

  return (
    <section className="bg-agiBlack pt-12">
      <div className="container mx-auto px-6 md:px-6">
        <div className="mx-auto max-w-3xl py-16 shadow-sm dark:shadow-none border-agiGray border-b relative">
          <div className="absolute top-0 right-0">
            <div className='flex gap-2'>
              <Button size="sm" variant="outline" onClick={onEdit}>
                Edit Profile
              </Button>
              <Button size='sm' variant="outline" onClick={handleLogout}>
                Log out
              </Button>
            </div>
          </div>

          <div className="flex items-center md:justify-start justify-center md:gap-12 gap-4 md:flex-row flex-col ">
            <img
              alt={user.name}
              className="h-[128px] w-[128px] rounded-full object-cover border-agiDarkGray"
              src={user.photoURL || "/default-avatar.png"}
            />

            <div className='w-full'>
              <div className='flex flex-col justify-center items-center md:items-start gap-4'>
                <div className='flex w-full justify-between items-center gap-4 md:flex-row flex-col'>
                <h1 className="text-3xl md:text-4xl font-bold text-gray-50">{user.name}</h1>
                <p className="text-sm text-agiGray md:text-left text-center">{user.email}</p>
                </div>
                
                <p className="text-md text-agiLightGray w-full md:text-left text-center">{user.headline}</p>
                <div className="flex gap-6 justify-center items-center pt-2 flex-wrap">
                  {/* <div className='text-agiLightGray'>{user.email}</div> */}
                  {user.linkedInURL && (
                    <a className="flex items-center gap-2 text-agiLightGray hover:text-white group" href={user.linkedInURL} target='_blank'>
                      <FaLinkedin className="" />
                      <p className="text-sm font-medium group-hover:underline">
                        {getSocialHandle(user.linkedInURL, /https:\/\/|www\.|linkedin\.com\/in\/|\//gi)}
                      </p>
                    </a>
                  )}
                  {user.githubURL && (
                    <a className="flex items-center gap-2 text-agiLightGray hover:text-white group" href={user.githubURL} target='_blank'>
                      <FaGithub className="" />
                      <p className="text-sm font-medium group-hover:underline">
                        {getSocialHandle(user.githubURL, /https:\/\/|www\.|github\.com\//gi)}
                      </p>
                    </a>
                  )}
                  {user.twitterURL && (
                    <a className="flex items-center gap-2 text-agiLightGray hover:text-white group" href={user.twitterURL} target='_blank'>
                      <FaTwitter className="" />
                      <p className="text-sm font-medium group-hover:underline">
                        {getSocialHandle(user.twitterURL, /https:\/\/|www\.|twitter\.com\//gi)}
                      </p>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserBasicInfo;
