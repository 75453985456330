import React from 'react';
import PropTypes from 'prop-types';

import BlankTicket from '../img/BlankTicket.png';

const ApprovedApplicationCard = ({ date, eventName, profileName, profileHeadline }) => {
  return (
    <div className="transition-all relative w-full overflow-hidden rounded-2xl font-DMMono mb-6 hover:scale-[101%] hover:rotate-[-1deg] hover:hue-rotate-[10deg]" style={{ userSelect: 'none' }}>
      <img
        src={BlankTicket}
        alt="Ticket"
        className="absolute"
      />

      <div className="relative z-10 p-4 sm:p-8 md:p-12 text-white flex flex-col items-between justify-between h-full aspect-[2/1]">
        <div className="flex justify-between ">
          <p className="uppercase md:text-base text-xs">{eventName}</p>
          <p className="uppercase md:text-base text-xs">@ AGI HOUSE</p>
        </div>
      
        <div className="">
          <p className="font-semibold md:pb-4 pb-1 md:text-4xl text-lg">{profileName}</p>
          <p className="md:text-base opacity-75 text-xs md:block hidden">{profileHeadline}</p>
        </div>
        <p className="uppercase md:text-base text-xs">{date}</p>
      </div>
    </div>
  );
};

ApprovedApplicationCard.propTypes = {
  date: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  profileHeadline: PropTypes.string.isRequired,
};

export default ApprovedApplicationCard;
