// src/components/ui/logoIcon.js

import React from 'react';

const LogoIcon = (props) => {
  return (
    <img
      {...props}
      src="/agihouse-logo.png"
      alt="Palette Icon"
      width="24"
      height="24"
    />
  );
};
export { LogoIcon }