import axios from 'axios';
import { Project } from './projectsService';
import { User } from './usersService';
import { Event } from './eventsService';
import { getActiveToken } from './authService';

import { API_URL } from './apiService';
const QUERY_ENDPOINT = 'query/new';
const LOAD_MORE_ENDPOINT = 'load_more/';

interface RequestPayload {
  prompt: string;
  user_id: string;
}

interface EventInfo {
  questions: string[];
  answers: string[];
  status: string;
}

export interface Profile {
  user: User;
  projects: Project[];
  events: Event[];
  eventInfo?: EventInfo; // for user application info
}

interface QueryData {
  response: Profile[];
  id: string;
}

interface Employees {
  id: string;
  name: string;
  headline: string;
  current: boolean;
}

interface CompanyQueryData {
  response: CompanyProfile[];
  id: string;
}

interface CompanyById {
  response: CompanyProfile[];
}

interface TalentById {
  response: Profile[];
}

// returned on company search
export interface CompanyProfile {
  description: string;
  website: string;
  industry: string;
  name: string;
  tagline: string;
  score: number;
  employees: Employees[];
  linkedInURL: string;
  research?: CompanyResearch;
  isTracked?: boolean;

  // unused from linkedin?
  // logo: string;
  // people: string[];
  // tagline: string;
  // companySize: string;
}

// RESEARCH INTERFACES
interface HQ {
  country: string;
  city: string;
  postal_code: string;
  line_1: string;
  is_hq: boolean;
  state: string;
}

interface Leadership {
  name: string;
  linkedin: string;
  position: string;
}

interface RevenueDataByYear {
  year: number;
  revenue: number;
}

interface FundingData {
  round: string;
  amount: string;
  investors: string[];
}

interface NotableProduct {
  url: string;
  name: string;
  description: string;
  USP: string;
}

interface KeyCustomer {
  name: string;
  linkedin: string;
  user_id?: string;
  company_id?: string;
}

interface Article {
  url: string;
  content: string;
}

interface Paper {
  link: string;
  title: string;
  citations: string[];
}

interface AiSummary {
  research: string[];
  people: string[];
  papers: Paper[];
  ai_tags: string[];
  ai_collaborations: {
    company_name: string;
    website: string;
  }[];
}

export interface CompanyResearch {
  name: string;
  linkedInURL: string;
  founded_year: number;
  hq?: HQ[];
  leadership?: Leadership[];
  mission?: string;
  revenue_data_by_year?: RevenueDataByYear[];
  funding_data?: FundingData[];
  notable_products?: NotableProduct[];
  customer_segment?: string;
  target_market?: string;
  key_customers?: KeyCustomer[];
  competitors?: string[];
  collaborators?: string[];
  market_share?: string;
  articles?: Article[];
  ai_summary?: AiSummary[];
}

// RESEARCH INFERFACES END

export const searchQuery = async (payload: RequestPayload): Promise<QueryData> => {
  const idToken = await getActiveToken();

  try {
    console.log('searchQuery:', payload);
    const searchResults = await axios.post<QueryData>(`${API_URL}${QUERY_ENDPOINT}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,

      },
    });
    console.log('searchQuery:', searchResults);
    return searchResults.data;
  } catch (error) {
    throw new Error(`searchQuery failed: ${error}`);
  }
};

export const loadMoreProfileResults = async (session_id: string): Promise<QueryData> => {
  const idToken = await getActiveToken();

  try {
    const payload = { session_id };
    const loadMoreResults = await axios.post<QueryData>(`${API_URL}${LOAD_MORE_ENDPOINT}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,

      },
    });
    console.log('searchQuery:', loadMoreResults);
    return loadMoreResults.data;
  } catch (error) {
    throw new Error(`searchQuery failed: ${error}`);
  }
};

export const searchCompaniesQuery = async (payload: RequestPayload): Promise<CompanyQueryData> => {
  const idToken = await getActiveToken();

  try {
    console.log('searchCompaniesQuery:', payload);
    const searchResults = await axios.post<CompanyQueryData>(`${API_URL}company/query/`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,

      },
    });
    return searchResults.data;
  } catch (error) {
    throw new Error(`searchCompaniesQuery failed: ${error}`);
  }
};

export const loadMoreCompanies = async (session_id: string): Promise<CompanyQueryData> => {
  const idToken = await getActiveToken();

  try {
    const payload = { session_id };
    const loadMoreResults = await axios.post<CompanyQueryData>(`${API_URL}${LOAD_MORE_ENDPOINT}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,

      },
    });
    return loadMoreResults.data;
  } catch (error) {
    throw new Error(`loadMoreCompanies failed: ${error}`);
  }
};

export const fetchCompanyById = async (company_id: string): Promise<CompanyById> => {
  const idToken = await getActiveToken();

  try {
    const response = await axios.get<CompanyById>(`${API_URL}company/id/${company_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,

      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`fetchCompanyById failed: ${error}`);
  }
}

export const fetchTalentById = async (talent_id: string): Promise<TalentById> => {
  const idToken = await getActiveToken();

  try {
    const response = await axios.get<TalentById>(`${API_URL}user/id/${talent_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,

      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`fetchTalentById failed: ${error}`);
  }
}