import { type ClassValue, clsx } from "clsx"
import { Timestamp } from "firebase/firestore";
import { twMerge } from "tailwind-merge"
import { DateTime } from "luxon";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

export const isUpcomingEvent = (timestamp: any): boolean => {
  // Convert the timestamp to a Luxon DateTime object in UTC
  const eventDate = DateTime.fromJSDate(new Date(timestamp)).setZone('UTC');
  // console.log('eventDate in UTC:', eventDate.toISO());

  // Convert the eventDate to 'America/Los_Angeles' timezone
  const eventDateInLA = eventDate.setZone('America/Los_Angeles');
  // console.log('eventDate in LA Time:', eventDateInLA.toISO());

  // Get the current date and time in the 'America/Los_Angeles' timezone
  const now = DateTime.now().setZone("America/Los_Angeles");
  // console.log('now in LA Time:', now.toISO());

  // Check if the event is today or in the future
  const isToday = eventDateInLA.hasSame(now, 'day');
  const isFuture = eventDateInLA > now;

  return isToday || isFuture;
};
