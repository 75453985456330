import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import React, { useEffect, useRef, useState } from 'react';
import { Button } from "../ui/button";
import { FaEdit } from 'react-icons/fa';
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { SimpleUser, updateUserInfo } from '../../services/usersService';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'; // USED FOR PHOTO UPLOAD
import { storage, auth } from '../../lib/firebaseConfig'; // USED FOR PHOTO UPLOAD
import { useAuth } from '../../AuthContext';

interface EditProfileDialogProps {
  isOpen: boolean;
  onClose: () => void;
  userInfo: SimpleUser;
}

const extractHandle = (url: string) => {
  if (!url) return ''
  return url.substring(url.lastIndexOf('/') + 1);
};

const EditProfileDialog: React.FC<EditProfileDialogProps> = ({ isOpen, onClose, userInfo }) => {
  const [editedUserInfo, setEditedUserInfo] = useState<SimpleUser>(userInfo);
  const [handles, setHandles] = useState({
    linkedin: userInfo.linkedInURL,
    github: userInfo.githubURL,
    twitter: userInfo.twitterURL
  });
  const [errors, setErrors] = useState<{ name?: string, headline?: string, linkedin?: string }>({});
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setUser } = useAuth();

  useEffect(() => {
    setHandles({
      linkedin: userInfo.linkedInURL,
      github: userInfo.githubURL,
      twitter: userInfo.twitterURL
    });
  }, [userInfo.linkedInURL, userInfo.githubURL, userInfo.twitterURL]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUserInfo({
      ...editedUserInfo,
      [name]: value,
    });
    validateField(name, value);
  };


  const validateField = (name: string, value: string) => {
    const newErrors = { ...errors };
    if (name === 'name' && !value) {
      newErrors.name = "Name is required.";
    } else if (name === 'name') {
      delete newErrors.name;
    }
    if (name === 'headline' && !value) {
      newErrors.headline = "Headline is required.";
    } else if (name === 'headline') {
      delete newErrors.headline;
    }
    if (name === 'linkedin' && !value) {
      newErrors.linkedin = "LinkedIn is required.";
    } else if (name === 'linkedin') {
      delete newErrors.linkedin;
    }
    setErrors(newErrors);
  };

  // USED FOR PHOTO UPLOAD
  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const storageRef = ref(storage, `profile_photos/${auth.currentUser.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      setUploading(true);
      uploadTask.on('state_changed',
        (snapshot) => {
          // Optional: Track upload progress here if desired
        },
        (error) => {
          console.error("Upload failed", error);
          setUploading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setEditedUserInfo((prevInfo) => ({
              ...prevInfo,
              photoURL: downloadURL,
            }));
            setUploading(false);
          });
        }
      );
    }
  };

  const handleSave = async () => {
    if (!errors.name && !errors.headline && !errors.linkedin) {
      const updated_user: SimpleUser = {
        id: editedUserInfo.id,
        email: editedUserInfo.email,
        name: editedUserInfo.name,
        headline: editedUserInfo.headline,
        linkedInURL: handles.linkedin,
        githubURL: handles.github ? handles.github : '',
        twitterURL: handles.twitter ? handles.twitter : '',
        name_lower: editedUserInfo.name.toLowerCase(),
        role: editedUserInfo.role || '',
        ai_project: editedUserInfo.ai_project || '',
        photoURL: editedUserInfo.photoURL || '',
      };
      await updateUserInfo(updated_user);
      setUser(updated_user);
      onClose();
    }
  };
  

  const handleCancel = () => {
    if (isOpen && (!editedUserInfo.name || !editedUserInfo.headline || !handles.linkedin)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        name: "Name is required.",
        headline: "Headline is required.",
        linkedin: "LinkedIn is required."
      }));
    } else {
      setEditedUserInfo(userInfo); // Reset changes
      setErrors({}); // Reset errors
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleCancel}>
      <DialogContent className="md:max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-white">{isOpen ? 'Complete Your Profile' : 'Edit Profile'}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-6">
          <div className="grid grid-cols-1 gap-4">
            <div className="flex items-center gap-4">
              <div className="relative h-20 w-20 shrink-0 overflow-hidden rounded-full">
                <img
                  alt={editedUserInfo.name}
                  className="h-full w-full object-cover"
                  height={80}
                  src={editedUserInfo.photoURL || "/default-avatar.png"}
                  style={{ aspectRatio: "80/80", objectFit: "cover" }}
                  width={80}
                />
                {/* // USED FOR PHOTO UPLOAD */}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                  className="hidden"
                  ref={fileInputRef} // Attach ref to file input
                />
                <Button
                  className="absolute bottom-[0px] right-[0px] h-20 w-20 rounded-full bg-[#00000075] text-white hover:bg-[#00000090] hover:text-white"
                  size="sm"
                  onClick={() => fileInputRef.current?.click()} // Trigger file input click on button click
                >
                  <FaEdit className="h-4 w-4" />
                  <span className="sr-only">Change avatar</span>
                </Button>
              </div>
              <div className="w-full">
                <Label htmlFor="name" className="text-agiLightGray font-light">Name <span className="text-red-500">*</span></Label>
                <Input
                  className="w-full"
                  name="name"
                  value={editedUserInfo.name}
                  onChange={handleChange}
                  type="text"
                  required
                  id="name"
                />
                {errors.name && <p className="text-red-500 text-sm pt-1 font-light">{errors.name}</p>}
                {isOpen && !editedUserInfo.name && <p className="text-sm text-agiLightGray">Please enter your full name.</p>}
              </div>
            </div>
            <div>
              <Label htmlFor="headline" className="text-agiLightGray font-light">Headline (One-line bio) <span className="text-red-500">*</span></Label>
              <Input
                className=""
                name="headline"
                value={editedUserInfo.headline || ''}
                onChange={handleChange}
                id="headline"
                required
              />
              {errors.headline && <p className="text-red-500 text-sm pt-1 font-light">{errors.headline}</p>}
              {isOpen && !editedUserInfo.headline && <p className="text-sm text-agiLightGray">Provide a brief headline about yourself.</p>}
            </div>
            <div>
              <Label htmlFor="linkedin" className="text-agiLightGray font-light">LinkedIn URL<span className="text-red-500">*</span></Label>
              <p className="text-agiGray5 text-sm pb-1 font-light">eg. https://linkedin.com/in/johnsmith</p>
              <div className="flex gap-1 justify-center items-center">
                {/* <p className="text-white font-semibold">@</p> */}
                <Input
                  className=""
                  name="linkedin"
                  value={handles.linkedin}
                  onChange={(e) => {
                    setHandles({ ...handles, linkedin: e.target.value });
                    validateField('linkedin', e.target.value);
                  }}
                  id="linkedin"
                  required
                />
              </div>
              {errors.linkedin && <p className="text-red-500 text-sm pt-1 font-light">{errors.linkedin}</p>}
            </div>
            <div>
              <Label htmlFor="github" className="text-agiLightGray font-light">GitHub URL</Label>
              <p className="text-agiGray5 text-sm pb-1 font-light">eg. https://github.com/johnsmith</p>

              <div className="flex gap-1 justify-center items-center">
                {/* <p className="text-white font-semibold">@</p> */}
                <Input
                  className=""
                  name="github"
                  value={handles.github}
                  onChange={(e) => setHandles({ ...handles, github: e.target.value })}
                  id="github"
                />
              </div>
            </div>
            <div>
              <Label htmlFor="twitter" className="text-agiLightGray font-light">Twitter/X URL</Label>
              <p className="text-agiGray5 text-sm pb-1 font-light">eg. https://twitter.com/johnsmith</p>

              <div className="flex gap-1 justify-center items-center">
                {/* <p className="text-white font-semibold">@</p> */}
                <Input
                  className=""
                  name="twitter"
                  value={handles.twitter}
                  onChange={(e) => setHandles({ ...handles, twitter: e.target.value })}
                  id="twitter"
                />
              </div>
            </div>
          </div>
          {isOpen && (!editedUserInfo.name || !editedUserInfo.headline || !handles.linkedin) && (
            <p className="text-red-500 text-sm mt-4">A complete profile is required for event registration.</p>
          )}
          <DialogFooter>
            <Button variant="white" onClick={handleSave} disabled={!editedUserInfo.name || !editedUserInfo.headline || !handles.linkedin || uploading}>
              {uploading ? "Uploading..." : "Save Changes"}
            </Button>
            <Button variant="outline" onClick={handleCancel} disabled={uploading}>
              Cancel
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditProfileDialog;
