import { Link } from 'react-router-dom';

import { Button } from './ui/button';
import React from 'react'
import { truncateText } from '../lib/utils';

import { isUpcomingEvent } from "src/lib/utils";

interface EventCardProps {
  imageSrc: string;
  title: string;
  description: string;
  date: string; // This will be the timestamp in string format
  eventLink: string;
  status?: string; // Optional status prop
  timestamp?: Date;
  access?: string;
  showImage: boolean;
}

export const EventCard: React.FC<EventCardProps> = ({ imageSrc, title, description, date, eventLink, status, timestamp, access, showImage }) => {

  const isUpcoming = isUpcomingEvent(timestamp);

  return (
    <Link to={eventLink}>
      <div className="overflow-hidden hover:cursor-pointer relative rounded-2xl p-0 flex flex-col justify-between bg-agiDarkGray border-agiMediumGray hover:border-agiGray border h-full"
      >

        {/* If you have a status for the upcoming event */}
        {status && isUpcoming && (
          <span className={`absolute top-1 left-1 text-white text-xs font-bold px-4 py-1 rounded-full z-50 ${status === 'approved' ? 'bg-plurple-gradient' : 'bg-orellow-gradient'}`}>    {isUpcoming ? (status === 'approved' ? 'You are going' : 'Pending') : 'Event Over'}
          </span>
        )}

        {/* If event is over*/}
        {!isUpcoming && (
          <span className={"absolute top-1 left-1 text-white text-xs font-bold px-4 py-1 rounded-full z-50 bg-agiBlack bg-opacity-75 backdrop-blur-lg"}>
            Event Over
          </span>
        )}

        {/* Admin or Unlisted or Member */}
        {access && access !== "public" && (
              <span className={`absolute top-1 right-1 text-white text-xs font-bold px-4 py-1 rounded-full z-50 bg-agiBlack bg-opacity-75 backdrop-blur-lg`}>   {`${access.charAt(0).toUpperCase() + access.slice(1)} Event`}
          </span>
            )}


        <div>
          {showImage && (
  <div className="relative w-full pb-[56.25%] overflow-hidden">
    <img
      alt={title}
      className="absolute top-0 left-1/2 w-full h-full object-cover transform -translate-x-1/2"
      src={imageSrc}
      style={{
        objectFit: "cover",
        objectPosition: "top center",
      }}
    />
  </div>
)}


          <div className="p-4">
            <h3 className="text-lg font-bold text-gray-50">{title}</h3>
          </div>
        </div>
      </div>
    </Link>
  );
}

const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M8 2v4" />
    <path d="M16 2v4" />
    <rect width="18" height="18" x="3" y="4" rx="2" />
    <path d="M3 10h18" />
  </svg>
);
