// Updated to Mongo Database 7/9. TASK: Getting names from teamMembers ids, is it not going to the people??? 7/17
import React, { useEffect, useState } from 'react';
import { Button } from './ui/button';
import { SquarePen } from 'lucide-react';
import { Project, TeamMember} from '../services/projectsService';
import { FaExternalLinkAlt, FaPen } from 'react-icons/fa';

interface ProjectCardProps {
  project: Project;
  isOwner?: boolean;
  hideBackground?: boolean;
  showEventLabel?: boolean;
  onEdit?: () => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, isOwner = false, showEventLabel = false, onEdit, hideBackground }) => {
  const [teamMembers, setTeamMembers] = useState<string[]>([]);
  const [eventName, setEventName] = useState<string | null>(null);

  useEffect(() => {
    const memberNames = project.teamMembers.map((member: TeamMember) => member.member_name);

    setTeamMembers(memberNames);
    // console.log('memberNames:', memberNames);
  }, [project.teamMembers]);

  useEffect(() => {
    if (showEventLabel) {
      setEventName(project.event?.event_name || "Event name not found.");
    }
  }, [project.event, showEventLabel]);

  return (
    <div className={`${hideBackground ? "bg-transparent" : "bg-agiDarkGray"} rounded-2xl px-6 py-2 border-agiMediumGray border relative`}>
      {project.awardName && (
        <span
          className={`absolute top-1 ${project.imageSrc ? 'right-1' : 'left-1'} ${project.awardWeight === 1
              ? 'bg-gold-gradient text-[#AA6A0A]'
              : project.awardWeight === 2
                ? 'bg-silver-gradient text-[#62727D]'
                : project.awardWeight === 3
                  ? 'bg-bronze-gradient text-[#8B4215]'
                  : 'bg-agiGray text-agiLightGray'
            } text-xs font-bold px-4 py-1 rounded-full`}>
          {project.awardName}
        </span>
      )}
      {project.imageSrc && (
        <div className="relative h-40 overflow-hidden rounded-lg">
          <img
            alt={project.name}
            className="h-full w-full object-cover"
            height={240}
            src={project.imageSrc}
            style={{ aspectRatio: "400/240", objectFit: "cover" }}
            width={400}
          />
        </div>
      )}
      <div className={`my-4 flex flex-col justify-left gap-4 ${project.awardName ? 'mt-8' : ''} `}>
        <div className='flex items-center justify-between'>
          <h3 className="text-lg font-bold text-white">{project.name}</h3>
          {isOwner && onEdit && (
            <Button
              className={`p-1 rounded-full border-0`}
              size="sm"
              variant="outline"
              onClick={onEdit}
            >
              {/* Edit */}
              <FaPen></FaPen>
            </Button>
          )}
        </div>
        {project.description && (
          <p className="text-base text-agiLightGray break-words"><span className='text-white'></span> {project.description}</p>
        )}
        {project.techStack && (
          <p className="text-sm text-agiLightGray break-words">Stack: {project.techStack}</p>
        )}
{showEventLabel && eventName && (
          <p className="text-sm text-agiLightGray">Event: {eventName}.</p>
        )}
       
        <div className='flex gap-4'>
        {project.slidesURL && (
          <a href={project.slidesURL} target="_blank" rel="noreferrer" className="text-agiLightGray break-words underline hover:text-blue-400">Slides</a>
        )}
        {project.githubRepoURL && (
          <a href={project.githubRepoURL} target="_blank" rel="noreferrer" className=" text-agiLightGray break-words underline hover:text-blue-400">Github</a>
        )}
        </div>
        
        <div className="flex flex-wrap justify-left gap-1 mt-1">
          {teamMembers.map((member, index) => (
            <span key={index} className="bg-agiMediumGray px-3 py-2 rounded-full text-xs text-white">
              {member}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
