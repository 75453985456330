import React, { useEffect, useState } from 'react';
import { Button } from '../ui/button';
import CreateEventDialog from '../admin/CreateEventDialog';
import { useLocation, Link } from 'react-router-dom';
import { LogoIcon } from '../ui/logoIcon';
import { UserRoundIcon } from 'lucide-react';
import { SimpleUser, fetchSimpleUserById } from '../../services/usersService';

interface NaviBarProps {
  theUser: SimpleUser;
}

const NaviBar: React.FC<NaviBarProps> = ({ theUser }) => {
  const [isAdminUser, setIsAdminUser] = useState(false); // render admin nav links if true
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const location = useLocation();

  // this does not work if the user account is new.
  useEffect(() => {
    if (theUser !== null) {
      // const fetchProfile = async () => {
      //   const simpleUserData = await fetchSimpleUserById(theUser.id)
      //   if (simpleUserData.response.role === 'admin') {
      //     setIsAdminUser(true);
      //   }
      // };
      // fetchProfile();
      if (theUser.role === 'admin') {
            setIsAdminUser(true);
          } else {
            setIsAdminUser(false);
          }
    }
  }, [theUser]);

  const isAdminPath = location.pathname === '/admin';

  return (
    <header className="bg-agiBlack">
        <div className={`${isAdminPath ? '' : 'container'} mx-auto flex h-16 items-center justify-between px-4 md:px-6`}>
          <Link className="flex items-center gap-2" to="/events">
          <LogoIcon className="h-6 w-6 text-gray-50 hover:text-gray-300" />
          <p className="font-bold text-lg text-white"><span className='font-DMMono'>AGI</span> <span className='font-DMMono'>HOUSE</span></p>
        </Link>
        <div className="flex items-center justify-center gap-6 font-medium">
          {isAdminUser && (
            <>
              <Link to="/admin" className="text-md text-agiLightGray hover:text-white  flex justify-center items-center">
                  <Button
                  variant="nav"
                  size="nopad">
                              Database
                  </Button>
              </Link>

              <Button
                onClick={() => setIsDialogOpen(true)}
                variant="nav"
                size="nopad"
                className='text-md text-agiLightGray hover:text-white  flex justify-center items-center'
              >
                Create Event
              </Button>

              <CreateEventDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
              />
            </>
          )}

          <Link to="/events" className="text-md text-agiLightGray hover:text-white  flex justify-center items-center">
          <Button
                  variant="nav"
                  size="nopad">
                        Events
                  </Button>
          </Link>

          {theUser !== null && theUser.role ? (
            <Link to="/profile" className="group h-8 w-8 text-sm text-agiLightGray hover:text-white  flex justify-center items-center rounded-full bg-agiGray border border-agiMediumGray hover:border-agiGray">
                  { theUser.name ? (
                     <p className="text-sm font-medium text-agiLightGray group-hover:text-white">{theUser.name[0]}</p>
                  ) : (
                     <UserRoundIcon className="text-lightGray group-hover:text-white w-[60%]" />
                  )}
                 
                  
            </Link>
          ) : (
            <Link to="/login">
              <Button
                className="text-md font-medium transition-all hover:scale-105"
                variant="white"
                size="sm"
              >
                Log in
              </Button>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default NaviBar;
