import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { auth } from './lib/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { fetchUserByIdToken } from './services/authService';
import { SimpleUser } from './services/usersService';

interface AuthContextType {
  user: SimpleUser | null;
  loading: boolean;
  setUser: React.Dispatch<React.SetStateAction<SimpleUser | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<SimpleUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const fetchedUser = await fetchUserByIdToken();
        setUser(fetchedUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);
  const val=useMemo(()=>({user,loading,setUser,setLoading}),[user,loading]);
  return (
    <AuthContext.Provider value={val}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
