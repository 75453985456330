// 7/10 Mongo Complete

import { useEffect, useState } from 'react';
import { EventCard } from "./EventCard";
import React from "react";
import TheHouse from '../img/TheHouse.png';
import Hands from '../img/HandsBlue.png';

import { fetchAllEvents, Event } from '../services/eventsService';
import { SimpleUser } from '../services/usersService';
import { FaImage } from 'react-icons/fa';


import { useReward } from 'react-rewards';

import { DateTime } from 'luxon';

interface EventProps {
  passedUser: SimpleUser;
}


const Events: React.FC<EventProps> = ({ passedUser }) => {
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);
  const [pastEvents, setPastEvents] = useState<Event[]>([]);
  const [filter, setFilter] = useState<'Upcoming' | 'Past'>('Upcoming');
  const [theUser, setTheUser] = useState<SimpleUser | null>(passedUser);
  const [showImage, setShowImage] = useState(true);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);

  const { reward } = useReward('rewardId', 'emoji', {
    emoji: ['🤖', 'AGI'],
    lifetime: 300,
    elementSize: 30,
    elementCount: 10,
    startVelocity: 60,
    spread: 80,
    zIndex: 9999,
  });


  useEffect(() => {
    // preload only upcoming events
    if (upcomingEvents.length === 0) {
      // console.log("user:", theUser);
      const loadEvents = async () => {
        const time_span = 'upcoming';
        const fetchedEvents = await fetchAllEvents(time_span);
        setUpcomingEvents(fetchedEvents.response);
        setIsLoadingEvents(false);
      };
      loadEvents();
    }
  }, []);

  useEffect(() => {
    // already have past events -> show on request
    if (pastEvents.length > 0) {
      return;
    }
    // no past events loaded -> load them and store
    if (pastEvents.length === 0 && filter === 'Past') {
      setIsLoadingEvents(true);
      const loadEvents = async () => {
        const time_span = 'past';
        const fetchedEvents = await fetchAllEvents(time_span);
        setPastEvents(fetchedEvents.response);
        setIsLoadingEvents(false);
      };
      loadEvents();
    }
  }, [filter]);

  const formatDate = (timestamp: Date | string) => {
    // Convert the timestamp to a DateTime object in UTC using Luxon
    const utcDateTime = DateTime.fromISO(timestamp.toString(), { zone: 'utc' });
    // Convert to Pacific Time (PST)
    const pstDateTime = utcDateTime.setZone('America/Los_Angeles');
    // Get today's date in PST
    const today = DateTime.now().setZone('America/Los_Angeles').startOf('day');
    // Get tomorrow's date in PST
    const tomorrow = today.plus({ days: 1 });
    // Get the day after tomorrow's date in PST
    const dayAfterTomorrow = today.plus({ days: 2 });
    // Get the weekday name in PST
    const weekday = pstDateTime.toFormat('cccc');
  
    if (pstDateTime.hasSame(today, 'day')) {
      return (
        <span>
          <span className="text-gray-50 font-semibold text-lg">Today </span>
          <span className="text-agiLightGray font-semibold text-lg">· {weekday}</span>
        </span>
      );
    } else if (pstDateTime.hasSame(tomorrow, 'day')) {
      return (
        <span>
          <span className="text-white font-semibold text-lg">Tomorrow </span>
          <span className="text-agiLightGray font-semibold text-lg">· {weekday}</span>
        </span>
      );
    } else if (pstDateTime.hasSame(dayAfterTomorrow, 'day')) {
      return (
        <span>
          <span className="text-white font-semibold text-lg">Day After Tomorrow </span>
          <span className="text-agiLightGray font-semibold text-lg">· {weekday}</span>
        </span>
      );
    } else {
      const fullDate = pstDateTime.toFormat('MMMM d');
      return (
        <span>
          <span className="text-white font-semibold text-lg">{fullDate} </span>
          <span className="text-agiLightGray font-semibold text-lg">· {weekday}</span>
        </span>
      );
    }
  };

  return (
    <div className="flex min-h-screen flex-col font-DMSans">
      <div className="text-white font-DMMono absolute -bottom-[10px] w-screen flex justify-center">
        <span id="rewardId"></span>
      </div>
      <main className="flex-1">
        <section className="bg-agiBlack flex flex-col items-center justify-center">
          <div className="relative mx-4 lg:rounded-2xl rounded-xl overflow-hidden max-w-[1400px]">
            {/* Main Image Layer */}
            <img
              src="/imgs/creationhandsV4.png"
              alt="The AGI House"
              className="w-[100vw] object-cover origin-center pointer-events-none"
              style={{
                position: 'relative',
              }}
            />
            <button
              className='absolute top-[39.75%] right-[48.70%] lg:w-4 lg:h-4 w-2 h-2 rounded-full opacity-0'
              onClick={() => reward()}
            >
            </button>

            {/* <div
  style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to top, #050709 0%, transparent 5%, transparent 95%, #050709 100%)',
    pointerEvents: 'none', // ensures the gradient doesn't block interactions with the image
  }}
/>

<div
  style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to right, #050709 0%, transparent 5%, transparent 95%, #050709 100%)',
    pointerEvents: 'none', // ensures the gradient doesn't block interactions with the image
  }}
/> */}


            {/* <div className="absolute inset-0 w-full h-full bg-glass-gradient"></div> */}

            {/* Overlay Layer */}
            {/* <div className="absolute inset-0 w-full h-full flex items-center justify-center">
              <div className="text-white flex flex-col items-center text-center py-24 rounded-lg lg:max-w-4xl w-full">
                <h1 className="text-3xl md:text-6xl font-DM font-bold"><span className='font-DMMono'>AGI</span> <span className='font-DMMono'>HOUSE</span> <span className='font-DMMono'>EVENTS</span></h1>
                <p className="text-lg md:text-xl mt-4 leading-relaxed md:leading-loose font-medium text-center hidden md:block">Exclusive gatherings for AI founders and researchers.
                </p>
              </div>
            </div> */}
          </div>

          <div className='flex justify-center items-center w-screen'>
            <div className="text-white flex flex-col md:pt-12 pt-6 justify-center items-center rounded-lg max-w-[1400px] w-full">
              <h1 className="text-3xl md:text-6xl font-DM font-bold">AGI House Events</h1>
              <p className="text-lg md:text-xl mt-4 leading-relaxed md:leading-loose font-medium hidden md:block">Exclusive gatherings for AI founders and researchers.
              </p>
            </div>
          </div>

          <div className='w-[65%] mx-12 h-[1px] mt-9 bg-agiGray hidden md:block'></div>

          <div className="mx-auto md:max-w-4xl w-full px-6 md:p-12 md:pt-9 pt-3 pb-9 bg-agiBlack">
            <div className="flex items-center align-center gap-6 md:justify-between md:flex-row-reverse flex-col">

              <div className='flex flex-row gap-6 items-center'>

                {theUser && theUser.role === 'admin' && (
                  <div>
                    <button
                      onClick={() => setShowImage(!showImage)}
                      className={`h-[52px] w-[52px] border rounded-full border-agiMediumGray bg-agiDarkGray flex items-center justify-center ${showImage ? 'text-white' : 'text-agiGray'}`}>
                      <FaImage className="text-xl" />
                    </button>
                  </div>
                )}
                <div className="flex flex-wrap items-center border rounded-full border-agiMediumGray  p-2 bg-agiDarkGray">
                  <button
                    onClick={() => setFilter('Upcoming')}
                    className={` rounded-full w-28 py-2 text-sm font-medium ${filter === 'Upcoming' ? 'bg-white text-agiBlack' : 'border-none text-white hover:bg-agiMediumGray'}`}
                  >
                    Upcoming
                  </button>
                  <button
                    onClick={() => setFilter('Past')}
                    className={` rounded-full w-28 py-2 text-sm font-medium ${filter === 'Past' ? 'bg-white text-agiBlack' : 'border-none text-white hover:bg-agiMediumGray'}`}
                  >
                    Past
                  </button>
                </div>


              </div>

              <h1 className="text-2xl md:text-4xl font-bold text-gray-50 md:block hidden">
                {filter} Events
              </h1>
            </div>

            <div className="mt-6">
              <div className='w-[1px] h-5 -mt-5'
                style={{
                  background: 'linear-gradient(to top, #3C4147 0%, transparent 100%)',
                }}
              ></div>
              <div className="relative border-l border-agiGray">
                {filter === "Upcoming" ? (
                  upcomingEvents.map((event, index) => {
                    const isToday = new Date(event.timestamp).toDateString() === new Date().toDateString();

                    return (
                      <div key={index} className="mb-8 lg:mb-10 ml-4">
                        <div>
                          {isToday ? (
                            <>
                              <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full bg-blue-500 border border-agiBlack animate-ping"></div>
                              <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full bg-blue-500 border border-agiBlack"></div>
                            </>
                          ) : (
                            <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full bg-agiGray border border-agiBlack"></div>
                          )}
                        </div>

                        <div className="mb-4">
                          {formatDate(event.timestamp)}
                        </div>
                        <EventCard
                          imageSrc={event.imageSrc || TheHouse}
                          title={event.name}
                          description={event.description}
                          date={event.date}
                          timestamp={event.timestamp}
                          eventLink={`/events/${event.slug}`}
                          access={event.access}
                          status={event.status}
                          showImage={showImage}
                        />
                      </div>
                    );
                  })
                ) : filter === "Past" ? (
                  pastEvents.map((event, index) => (
                    <div key={index} className="mb-8 lg:mb-10 ml-4">
                      <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full bg-agiGray border border-agiBlack"></div>
                      <div className="mb-4">
                        {formatDate(event.timestamp)}
                      </div>
                      <EventCard
                        imageSrc={event.imageSrc || TheHouse}
                        title={event.name}
                        description={event.description}
                        date={event.date}
                        timestamp={event.timestamp}
                        eventLink={`/events/${event.slug}`}
                        access={event.access}
                        status={event.status}
                        showImage={showImage}
                      />
                    </div>
                  ))
                ) : (
                  <div>No events available.</div>
                )}


                {isLoadingEvents === false ? (
                  <div className="ml-4">
                    <div>
                      <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full bg-agiGray border border-agiBlack"></div>
                    </div>
                    <div className='md:p-6 p-4 bg-agiDarkGray border-agiMediumGray w-full border rounded-2xl'>
                      <p className='text-lg font-bold text-white'>More events coming soon...</p>
                      <p className='text-agiLightGray mt-2'>
                        Follow us on <a href="https://twitter.com/agihouse_org" className='text-blue-500 underline' target="_blank" rel="noopener noreferrer">Twitter</a> and <a href="https://www.instagram.com/agihouse_org" className='text-blue-500 underline' target="_blank" rel="noopener noreferrer">Instagram</a> to be the first to hear about them.
                      </p>
                    </div>
                  </div>
                )
                  :
                  (
                    <div className="ml-4">
                      <div>
                        <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full bg-agiGray border border-agiBlack"></div>
                      </div>
                      <div className='md:p-6 p-4 bg-agiDarkGray border-agiMediumGray w-full border rounded-2xl'>
                        <p className='text-regular text-agiLightGray'>Loading {filter} 🤖 Events...</p>
                      </div>
                    </div>
                  )
                }




              </div>


              <div className='w-[1px] h-10'
                style={{
                  background: 'linear-gradient(to bottom, #3C4147 0%, transparent 100%)',
                }}
              ></div>
            </div>
          </div>

        </section>
      </main>
    </div>
  );
};

export default Events;