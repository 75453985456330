import axios from 'axios';
import { SimpleUser } from './usersService';
import { auth } from '../lib/firebaseConfig';

import { API_URL } from './apiService';

export const getActiveToken = async () => {
    
    if (!auth.currentUser) {
        // console.error('No active user');
        return null;
    }
    const idToken = await auth.currentUser.getIdToken();
    return idToken;
}

export const fetchUserByIdToken = async () : Promise<SimpleUser> => {
    const idToken = await getActiveToken();


    if (idToken === null) {
        alert('idToken === null. Please report this error to henry@agihouse.org.');

    }

    if (!auth.currentUser) {
        alert('no auth.currentUser. Please report this error to henry@agihouse.org.');
    }

    try {
        const response = await axios.get<SimpleUser>(`${API_URL}user/token`, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        // console.log('fetchUserByIdToken:', response.data);

        return response.data;
    } catch (error) {
        console.error('Error fetching user by ID token', error);
        alert('Error fetching user by ID token. Please report this error to henry@agihouse.org.');
        // ENSURE KICK USER OUT HERE.
        return null;
    }
};