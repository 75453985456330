import React, { useState, useEffect, useRef } from 'react';
import { Input } from "src/components/ui/input";
import { Button } from '../ui/button';
import { SearchIcon } from '../Icons';
import { FaCheckCircle, FaClone, FaDownload, FaMinusCircle, FaPlusCircle, FaThumbtack, FaTrash } from 'react-icons/fa';
// For CSV export
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import {
  searchQuery, loadMoreProfileResults, Profile, searchCompaniesQuery,
  CompanyProfile, loadMoreCompanies, fetchCompanyById, fetchTalentById
} from '../../services/searchService';
import TalentProfile from './TalentProfile2';
import CompanyProfileSheet from './CompanyProfileSheet';
import { truncateText } from '../../lib/utils';
import { ArrowBigUp } from 'lucide-react';

const cleanUpURL = (url) => {
  return "https://" + url.replace(/^(https?:\/\/)?(www\.)?/, '');
};

export default function TalentSearch() {
  const [searchState, setSearchState] = useState('Search Something');
  const [sessionID, setSessionID] = useState('');
  const [profilePrompt, setProfilePrompt] = useState('');
  const profilePromptRef = useRef('');
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [profilesList, setProfilesList] = useState<Profile[]>([]);
  const [selectedUserProfile, setSelectedUserProfile] = useState<Profile>(null);
  const [profileHoveredIndex, setProfileHoveredIndex] = useState(null);
  const [searchCategory, setSearchCategory] = useState('Talents');
  const [companies, setCompanies] = useState<CompanyProfile[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyProfile>(null);
  const [companyHoveredIndex, setCompanyHoveredIndex] = useState(null);
  const [companyPrompt, setCompanyPrompt] = useState('');
  const companyPromptRef = useRef('');
  const [companySearchSessionID, setCompanySearchSessionID] = useState('');
  const [extraProfileId, setExtraProfileId] = useState("");
  const [extraProfile, setExtraProfile] = useState(null);
  const [extraProfileType, setExtraProfileType] = useState(null);

  // bandaid fix :(
  const [extraProfileReady, setExtraProfileReady] = useState(false);

  // Extra is the layer on top. Used for exploring the inner details of a company profile or company.
  const selectExtra = (id, extraType) => {
    setExtraProfileReady(false);
    console.log("selectExtra:", id, extraType);
    setExtraProfileType(extraType);
    console.log("extraType updated. Old id:", extraProfileId, "new id:", id);
    setExtraProfileId(id);
  }
  const loadExtraProfile = async () => {
    // Load in extra profile if exists
    if (extraProfileId !== "") {
      if (extraProfileType === 'Company') {
        console.log("loading extra company profile...");
        const extraResponse = await fetchCompanyById(extraProfileId);
        console.log("extraResponse:", extraResponse);
        setExtraProfile(extraResponse.response)
      } else if (extraProfileType === 'Talent') {
        console.log("loading extra talent profile...");
        const extraResponse = await fetchTalentById(extraProfileId);
        console.log("extraResponse:", extraResponse);
        setExtraProfile(extraResponse.response)
      }
      setExtraProfileReady(true);
    }
  }
  useEffect(() => {
    // console.log("extraProfileId:", extraProfileId);
    if (extraProfileId === "") {
      setExtraProfile(null)
      setExtraProfileType(null)
    } else {
      console.log("loading extra profile...", extraProfileId)
      loadExtraProfile()
      
    }
  }, [extraProfileId]);

  // Refs for search bar.
  useEffect(() => {
    profilePromptRef.current = profilePrompt;
  }, [profilePrompt]);
  useEffect(() => {
    companyPromptRef.current = companyPrompt;
  }, [companyPrompt]);

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setExtraProfileId("")
      }
      if (event.key === 'Enter' || event.key === 'Return') {
        console.log(searchCategory)
        if (searchCategory === 'Talents') {
          initiateSearch();
        }
        if (searchCategory === 'Companies') {
          initiateSearchCompanies();
        }
      }
      if (event.key === '!') {
        if (extraProfileId === '' && searchCategory === 'Talents' && selectedUserProfile?.user.linkedInURL) {
          window.open(selectedUserProfile.user.linkedInURL, '_blank');
        }
        if (extraProfileId === '' && searchCategory === 'Companies' && selectedCompany?.linkedInURL) {
          window.open(cleanUpURL(selectedCompany.linkedInURL), '_blank');
        }
        if (extraProfileId !== '') {
          if (extraProfileType === "Talent") {
            // console.log("extraProfile:", extraProfile.user.linkedInURL);
            window.open(cleanUpURL(extraProfile.user.linkedInURL), '_blank');
          }
          if (extraProfileType === "Company") {
            window.open(cleanUpURL(extraProfile.linkedInURL), '_blank');
          }
        }
      }
      if (event.key === '@') {
        if (extraProfileId === '' && searchCategory === 'Talents' && selectedUserProfile?.user.githubURL) {
          window.open(cleanUpURL(selectedUserProfile.user.githubURL), '_blank');
        }
        if (extraProfileId === '' && searchCategory === 'Companies' && selectedCompany?.website) {
          window.open(cleanUpURL(selectedCompany.website), '_blank');
        }
        if (extraProfileId !== '') {
          if (extraProfileType === "Talent") {
            window.open(cleanUpURL(extraProfile.user.githubURL), '_blank');
          }
          if (extraProfileType === "Company") {
            window.open(cleanUpURL(extraProfile.website), '_blank');
          }
        }
      }
      if (event.key === '#') {
        if (extraProfileId === '' && selectedUserProfile?.user.twitterURL) {
          window.open(selectedUserProfile.user.twitterURL, '_blank');
        }
        if (extraProfileId !== '') {
          console.log("extraProfile:", extraProfile)
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [selectedUserProfile, searchCategory, selectedCompany, extraProfile]);

  const initiateSearch = async () => {
    if (searchState === "Searching") return;
    const message = profilePromptRef.current;
    console.log("initiateSearch:", message)
    if (message !== "") {
      setSearchState("Searching...");
      console.log("Searching for:", message);
      // Clean state for new arriving people.
      setSelectedUserProfile(null);
      setProfiles([]);
      interface RequestPayload {
        prompt: string;
        user_id: string;
      }
      try {
        const payload: RequestPayload = { prompt: message, user_id: 'ABC' }; // ADD USER ID LOL
        const searchResult = await searchQuery(payload);
        console.log("search result:", searchResult)
        setSessionID(searchResult.id);
        const resultCount = searchResult.response.length;
        setSearchState(`Building ${resultCount} Profiles...`);
        if (resultCount === 0) {
          setSearchState("No results.");
          return;
        } else {
          await setProfiles(searchResult.response)
          // auto open to first person
          setProfileHoveredIndex(0);
          setSelectedUserProfile(searchResult.response[0]);
          setSearchState("");
        }
      } catch (err) {
        console.log("API response error:", err);
      }
    }
  }

  const initiateLoadMore = async () => {
    setSearchState("Attempting load more profiles...");
    try {
      const payload = sessionID
      console.log("load more payload:", payload);
      const loadResult = await loadMoreProfileResults(payload);
      console.log("load more result:", loadResult);
      if (loadResult.response.length === 0) {
        setSearchState("No more results.");
        return;
      }
      setProfiles(prevProfiles => [...prevProfiles, ...loadResult.response]);
      setSearchState("");
    } catch (err) {
      console.log("API response error:", err);
    }
  }

  const initiateSearchCompanies = async () => {
    if (searchState === "Searching") return;
    const message = companyPromptRef.current;
    console.log("initiateSearchCompanies:", message)
    if (message !== "") {
      setSearchState("Searching companies...");
      console.log("Searching for:", message);
      // Clean state for new arriving people.
      setSelectedCompany(null);
      setCompanies([]);
      interface RequestPayload {
        prompt: string;
        user_id: string;
      }
      try {
        const payload: RequestPayload = { prompt: message, user_id: 'ABC' }; // ADD USER ID LOL
        const searchResult = await searchCompaniesQuery(payload); // UPDATE THIS LOLLOLOLOL
        console.log("Company search result:", searchResult)
        setCompanySearchSessionID(searchResult.id);
        const resultCount = searchResult.response.length;
        setSearchState(`Building ${resultCount} company profiles...`);
        if (resultCount === 0) {
          setSearchState("No results.");
          return;
        } else {
          await setCompanies(searchResult.response)
          // auto open to first company
          setSelectedCompany(searchResult.response[0]);
          setCompanyHoveredIndex(0);
          setSearchState("");
        }
      } catch (err) {
        console.log("Company search error:", err);
      }
    }
  }

  const initiateLoadMoreCompanies = async () => {
    setSearchState("Attempting load more companies...");
    try {
      const payload = companySearchSessionID
      const loadResult = await loadMoreCompanies(payload);
      if (loadResult.response.length === 0) {
        setSearchState("No more results.");
        return;
      }
      setCompanies(prevCompanies => [...prevCompanies, ...loadResult.response]);
      setSearchState("");
    } catch (err) {
      console.log("Load more comapnies API response error:", err);
    }
  }

  // Exporting CSV
  
  const [listTitle, setListTitle] = useState('');
  const exportToCsv = (filename: string, data: any[]) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    console.log("export ready.")
    saveAs(blob, filename);
  };

  const handleExport = () => {
    console.log("starting export...");
    const csvData = profilesList.map((profile) => ({
      name: profile.user.name,
      email: profile.user.email,
      status: '', 
      // contactPlatform: '',
      headline: profile.user.headline,
      linkedin: profile.user.linkedInURL,
      twitter: profile.user.twitterURL,
      github: profile.user.githubURL,
      // userId: profile.user.id,
    }));
    if (listTitle !== "") {
      exportToCsv(`${listTitle}.csv`, csvData);
    } else {
      exportToCsv(`Untitled Group.csv`, csvData);

    }
  };
  // End Exporting CSV

  const toggleProfileInList = (profile) => {
    if (profilesList.some(p => p.user.id === profile.user.id)) {
      setProfilesList(profilesList.filter(p => p.user.id !== profile.user.id));
    } else {
      setProfilesList([...profilesList, profile]);
    }
  };

  const handleProfileHover = (profile, index) => {
    setSelectedUserProfile(profile);
    setProfileHoveredIndex(index);
  };

  const handleCompanyHover = (company, index) => {
    setSelectedCompany(company);
    setCompanyHoveredIndex(index);
  };

  const handleListProfileClick = (profile) => {
    setSelectedUserProfile(profile);
    setProfileHoveredIndex(null);
  };

  // const handleCopy = (text) => {
  //   navigator.clipboard.writeText(text)
  //     .then(() => {
  //       console.log('Text copied to clipboard');
  //     })
  //     .catch((err) => {
  //       console.error('Failed to copy text: ', err);
  //     });
  // };

  const toggleCompanyTracked = (company: CompanyProfile) => {
    console.log("Toggling company tracked status...");
    
    const updatedCompany = {
      ...company,
      isTracked: !company.isTracked,
    };
  
    setCompanies(companies.map(c => {
      if (c.linkedInURL === company.linkedInURL) {
        setSelectedCompany(updatedCompany);
        return updatedCompany;
      }
      return c;
    }));

    if (extraProfile && extraProfile.linkedInURL === company.linkedInURL) {
      setExtraProfile(updatedCompany);
    }
  
    // TODO: API Call to persist the change on the backend if necessary
  };

  
  

  return (
    <div className="border-y border-agiMediumGray flex items-center justify-center w-full max-w-[3000px] text-white" style={{ height: `calc(100vh - 128px)` }}>
      {/* Search Left */}
      {profilesList.length > 0 && (
        <div className='w-1/6 h-full border-r border-agiMediumGray'>
          <div className='flex flex-col p-4 items-center gap-4'>
            <div className='flex w-full justify-between items-start'>
              <FaTrash className='text-agiGray hover:text-agiLightGray hover:cursor-pointer' onClick={() => {
                setProfilesList([]);
                setListTitle("")
              }} />
              <input
                className="border-agiGray font-bold bg-transparent w-full mx-4 shadow-none appearance-none text-sm text-whit text-center text-bold focus:border-none focus:ring-0 placeholder:text-agiGray"
                placeholder="Untitled"
                value={listTitle}
                onChange={(e) => setListTitle(e.target.value)}
              />
              <FaDownload className='text-agiGray hover:text-agiLightGray hover:cursor-pointer' onClick={() => handleExport()}></FaDownload>
            </div>
            <div className='flex flex-col overflow-scroll gap-1 w-full'>
              {profilesList && profilesList.map((profile, index) => (
                <div className="group text-sm py-2 text-agiLightGray hover:text-white hover:cursor-pointer rounded-lg flex justify-center items-center gap-2" key={index}
                  onClick={() => handleListProfileClick(profile)}>
                  <FaMinusCircle
                    className="hidden group-hover:block text-agiGray hover:text-agiLightGray hover:cursor-pointer"
                    onClick={() => toggleProfileInList(profile)}
                  />
                  {profile.user.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Profile Left (Search) */}
      <div className='w-1/4 h-full border-r border-agiMediumGray'>
        <div className='h-full overflow-hidden'>
          <div className='px-4 pt-4 flex'>
            <div className='bg-transparent flex w-full rounded-lg border border-agiMediumGray overflow-hidden'>
              <button
                onClick={() => {
                  setSearchCategory('Talents');
                }}
                className={`rounded-md w-full py-2 text-sm font-medium ${searchCategory === 'Talents' ? 'bg-agiMediumGray text-white' : 'border-none text-white hover:bg-agiDarkGray'}`}
              >
                Talents
              </button>
              <button
                onClick={() => {
                  setSearchCategory('Companies');
                }}
                className={`rounded-md w-full py-2 text-sm font-medium ${searchCategory === 'Companies' ? 'bg-agiMediumGray text-white' : 'border-none text-white hover:bg-agiDarkGray'}`}
              >
                Companies
              </button>
            </div>
          </div>
          {/* Search for Talents */}
          {searchCategory === 'Talents' && (
            <div className={`relative ${(searchState === "Searching") ? 'opacity-50' : ''} px-4 pt-4`}>
              <SearchIcon onClick={() => initiateSearch()} className="hover:cursor-pointer absolute right-8 top-[28px] h-4 w-4 text-agiLightGray border-agiMediumGray" />
              <Input
                className="px-4 bg-transparent w-full shadow-none appearance-none text-base text-white rounded-lg placeholder:text-agiMediumGray border-agiMediumGray"
                placeholder="Search talents..."
                value={profilePrompt}
                onChange={(e) => setProfilePrompt(e.target.value)}
              />
            </div>
          )
          }
          {/* Search for Companies */}
          {searchCategory === 'Companies' && (
            <div className={`relative ${(searchState === "Searching") ? 'opacity-50' : ''} px-4 pt-4`}>
              <SearchIcon onClick={() => initiateSearchCompanies()} className="hover:cursor-pointer absolute right-8 top-[28px] h-4 w-4 text-agiLightGray border-agiMediumGray" />
              <Input
                className="px-4 bg-transparent w-full shadow-none appearance-none text-base text-white rounded-lg placeholder:text-agiMediumGray border-agiMediumGray"
                placeholder="Search companies..."
                // type="search"
                value={companyPrompt}
                onChange={(e) => setCompanyPrompt(e.target.value)}
              />
            </div>
          )
          }
          <div className="p-4 flex flex-col gap-2 justify-start items-center h-full overflow-scroll">
            {/* Talents results */}
            {searchCategory === 'Talents' && profiles.map((profile, index) => (
              <div
                key={index}
                onMouseEnter={() => handleProfileHover(profile, index)}
                className={`flex items-center justify-between px-4 py-4 w-full rounded-lg border border-agiDarkGray text-white ${profileHoveredIndex === index ? 'border-agiMediumGray bg-agiDarkGray' : ''}`}
              >
                <p>
                  <span className='text-agiGray pr-3'>{index + 1}</span>
                  {profile.user.name}
                </p>
                <div>
                  {profilesList.some(p => p.user.id === profile.user.id) ? (
                    <div
                      className='group p-2 rounded-lg bg-[00000000] hover:bg-agiGray hover:cursor-pointer'
                      onClick={() => toggleProfileInList(profile)}
                    >
                      <FaCheckCircle className="text-md text-blue-500 group-hover:text-agiLightGray" />
                    </div>
                  ) : (
                    <div
                      className='group p-2 rounded-lg bg-[00000000] hover:bg-agiGray hover:cursor-pointer'
                      onClick={() => toggleProfileInList(profile)}
                    >
                      <FaPlusCircle className="text-md text-agiGray group-hover:text-agiLightGray" />
                    </div>
                  )}
                </div>
              </div>
            ))}
            {/* Companies results */}
            {searchCategory === 'Companies' && companies.map((company, index) => (
              <div
                key={index}
                onMouseEnter={() => handleCompanyHover(company, index)}
                className={`flex items-center justify-start px-4 py-4 w-full rounded-lg border border-agiDarkGray text-white ${companyHoveredIndex === index ? 'border-agiMediumGray bg-agiDarkGray' : ''}`}
              >
                <div className='flex w-full items-center justify-between'>
                    <p>
                      <span className='text-agiGray pr-3'>{index + 1}</span>
                      {truncateText(company.name, 34)}
                    </p>
                    <FaThumbtack onClick={() => toggleCompanyTracked(company)} className={`hover:cursor-pointer text-lg ${company.isTracked && company.isTracked === true ? 'text-white' : 'text-agiGray'}`} />
                </div>
              </div>
            ))}
            {searchCategory === 'Talents' && (
              profiles.length > 0 && searchState === '' ? (
                <Button
                  variant="outline"
                  size="sm"
                  className='text-agiGray mt-2 mb-[256px] border-agiGray5 py-2 w-full rounded-lg'
                  onClick={initiateLoadMore}
                >
                  Load More Talents
                </Button>
              ) : (
                <p className='mb-[256px] mt-2 text-agiGray5'>{searchState}</p>
              )
            )}
            {searchCategory === 'Companies' && (
              companies.length > 0 && searchState === '' ? (
                <Button
                  variant="outline"
                  size="sm"
                  className='text-agiGray5 mt-2 mb-[256px] border-agiMediumGray py-2 w-full rounded-lg'
                  onClick={initiateLoadMoreCompanies}
                >
                  Load More Companies
                </Button>
              ) : (
                <p className='mb-[256px] mt-2 text-agiGray5'>{searchState}</p>
              )
            )}
          </div>
        </div>
      </div>
      {/* Profile Right */}
      <div className='relative w-3/4 h-full overflow-scroll'>
        {/* Layer 1 (top extra) */}
        {extraProfile != null && (
          <div className='absolute top-0 left-0 w-full h-full z-20 bg-[#00000090] p-4'>
            <div className='h-full border border-agiMediumGray rounded-lg flex flex-col overflow-scroll bg-agiBlack'>
              {/* FIX / CHECK overflow and scrolling */}
              <div className='relative px-12 flex w-full justify-end z-30'>
                <button className="flex gap-2 absolute rounded-lg justify-center items-center text-agiLightGray mt-4 hover:text-white" onClick={() => setExtraProfileId("")}>
                Close
                <div className='flex justify-center items-center text-xs font-bold pl-3 pr-3 h-6 border-[1.5px] border-agiMediumGray text-agiGray5 rounded-md font-DMMono'>
                ESC
              </div>
                </button>
              </div>
              {extraProfileType === 'Talent' ? (
                <div className=' flex justify-center items-center'>
                  {extraProfileReady ? (
                    <TalentProfile
                      profile={extraProfile}
                      onExtraSelect={selectExtra}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              ) : (
                extraProfileType === 'Company' && (

                  <div className=' flex justify-center items-center'>
                    {extraProfileReady ? (
                      <CompanyProfileSheet company={extraProfile}
                        onExtraSelect={selectExtra}
                        onTrackedToggle={toggleCompanyTracked}
                      />
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {/* Layer 2: bottom */}
        {selectedUserProfile != null && searchCategory === 'Talents' && (
          <div className='absolute top-0 left-0 w-full h-full z-10'>
            <TalentProfile
              profile={selectedUserProfile}
              onExtraSelect={selectExtra}
            />
          </div>
        )}
        {selectedCompany != null && searchCategory === 'Companies' && (
          <div className='absolute top-0 left-0 w-full h-full z-10'>
            <CompanyProfileSheet
              company={selectedCompany}
              onExtraSelect={selectExtra}
              onTrackedToggle={toggleCompanyTracked}
            />
          </div>
        )}
      </div>
    </div>
  );
}
