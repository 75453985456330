import React, { useState } from 'react';
import { FaLinkedin, FaGlobe, FaRegEye, FaRegEyeSlash, FaThumbtack } from 'react-icons/fa';
import { ArrowBigUp } from 'lucide-react';
import { Button } from '../ui/button';

const cleanUpURL = (url) => {
  return "https://" + url.replace(/^(https?:\/\/)?(www\.)?/, '');
};

export default function CompanyProfileSheet({ company, onExtraSelect, onTrackedToggle }) {

  const [activeTab, setActiveTab] = useState('about');

  // State for checkboxes
  const [showCurrent, setShowCurrent] = useState(true);
  const [showPast, setShowPast] = useState(false);
  const [showImportant, setShowImportant] = useState(false); // Ignored for now

  // Filter employees based on checkbox states
  const filteredEmployees = company.employees?.filter(employee => {
    if (showCurrent && employee.current) return true;
    if (showPast && !employee.current) return true;
    return false;
  }) || [];


  if (!company) {
    return <div className=''>Loading...</div>;
  }

  return (
    <div className='relative w-full h-full'>
      <div className="relative p-12 w-full h-full overflow-scroll z-0">
        <div className="mt-1 sm:absolute sm:right-12 gap-4 justify-center sm:items-end flex flex-col sm:pb-0 pb-8">
          {company.linkedInURL && (
            <a className="flex items-center justify-center gap-2 text-agiLightGray hover:text-white group" href={cleanUpURL(company.linkedInURL)} target='_blank'>
              <FaLinkedin className="text-xl" />
              <div className='flex justify-center items-center text-xs font-bold pl-1 pr-3 h-6 border-[1.5px] border-agiMediumGray text-agiGray5 rounded-md font-DMMono'>
                <ArrowBigUp className='h-4'></ArrowBigUp>1
              </div>
            </a>
          )}
          {company.website && (
            <a className="flex items-center justify-center gap-2 text-agiLightGray hover:text-white group" href={cleanUpURL(company.website)} target='_blank'>
              <FaGlobe className="text-xl" />
              <div className='flex justify-center items-center text-xs font-bold pl-1 pr-3 h-6 border-[1.5px] border-agiMediumGray text-agiGray5 rounded-md font-DMMono'>
                <ArrowBigUp className='h-4'></ArrowBigUp>2
              </div>
            </a>
          )}
        </div>

        <div className='flex flex-col justify-start gap-8'>
          <div className='flex gap-4 justify-start items-center'>
            <h2 className="text-5xl font-bold max-w-4xl leading-tight">{company.name}</h2>
            <FaThumbtack onClick={() => onTrackedToggle(company)} className={`hover:cursor-pointer text-2xl ${company.isTracked ? 'text-white' : 'text-agiGray'}`} />
          </div>

          <div className="flex w-fit justify-start gap-8 border-b border-agiGray pb-1">
            <button onClick={() => setActiveTab('about')} className={`text-base font-semibold ${activeTab === 'about' ? 'text-white' : 'text-agiGray5'}`}>About</button>
            <button onClick={() => setActiveTab('feed')} className={`text-base font-semibold ${activeTab === 'feed' ? 'text-white' : 'text-agiGray5'}`}>Feed</button>
            <button onClick={() => setActiveTab('people')} className={`text-base font-semibold ${activeTab === 'people' ? 'text-white' : 'text-agiGray5'}`}>People</button>
          </div>

          {activeTab === 'about' && (
            <div className="max-w-5xl flex flex-col gap-6">
              <div className='grid grid-cols-3 w-full gap-6'>
                <div className={`h-full flex flex-col ${company.research ? "col-span-2" : "col-span-3"}`}>
                  <h3 className="text-xl font-semibold mb-2 ml-2">Description</h3>
                  <div className={`flex flex-col gap-3 bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6 flex-grow`}>
                    {company.tagline && (
                      <p className='text-white mt-1'>{company.tagline}</p>
                    )}
                    {company.description && (
                      <p className='text-white mt-1'>{company.description}</p>
                    )}
                  </div>
                </div>

                {company.research && (
                  <div className={`h-full flex flex-col col-span-1`}>
                    <h3 className="text-xl font-semibold mb-2 ml-2">Funding Snapshot</h3>
                    <div className={`h-full flex flex-col gap-3 bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6 flex-grow`}>
                      <div className='flex flex-col gap-0'>
                        <p className='text-agiLightGray text-base'>Total Funding</p>
                        <p className='text-white mt-1'>$100M</p>
                      </div>
                      <div className='flex flex-col gap-0'>
                        <p className='text-agiLightGray text-base'>Last Funding</p>
                        <p className='text-white mt-1'>$25M - Series A - led by Sequoia Capital on August 12th, 2024.</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>



              {!company.research ? (
                <div className='flex flex-col gap-3 bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6'>
                  <h3 className="text-xl font-semibold">This company has not been researched.</h3>
                  <p className='text-white mt-1'>Want a detailed report on this company's AI activity, funding, partnerships, and products?</p>
                  <Button className='w-fit'>Deploy ResearcherGPT</Button>
                </div>
              ) : (
                <div className='flex flex-col gap-6'>
                  <div>
                    <h3 className="text-xl font-semibold mb-2 ml-2">AI Highlights</h3>
                    <div className='flex flex-col gap-3 bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6'>

                      {company.research.ai_summary ? (
                        <div className='flex flex-col gap-0'>
                          <p className='text-agiLightGray text-base'>AI Tags</p>
                          {company.research.ai_summary.ai_tags && company.research.ai_summary.ai_tags.length > 0 ? (
                            <div>
                              {company.research.ai_summary.ai_tags.map((ai_tag: string, index: number) => (
                                <p key={index} className='text-white px-2 py-1 border rounded-lg'>{ai_tag}</p>
                              ))}
                            </div>
                          ) : (
                            <p className='text-white mt-1'>No AI Tags available.</p>
                          )}

                        </div>

                      ) : (
                        <div>
                          <p className='text-white mt-1'>No AI Highlights available.</p>

                        </div>
                      )}
                    </div>
                  </div>


                  <div>
                    <h3 className="text-xl font-semibold mb-2 ml-2">Products</h3>



                    {company.research.notable_products && company.research.notable_products.length > 0 ? (
                      <div className='grid grid-cols-2 gap-4'>
                        {company.research.notable_products.map((product, index) => (
                          // card
                          <a
                            key={index}
                            href={product.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className='flex flex-col gap-3 bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6 hover:bg-agiMediumGray transition-colors'
                          >
                            <div>
                              <h4 className="font-semibold underline">{product.name}</h4>
                              <p>{product.description}</p>
                              <p className="italic">{product.USP}</p>
                            </div>
                          </a>
                        ))}
                      </div>
                    ) : (
                      <p className='text-white mt-1'>No products available.</p>
                    )}


                  </div>



                  <div>
                    <h3 className="text-xl font-semibold mb-2 ml-2">Landscape</h3>
                    <div className='flex flex-col gap-3 bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6'>
                      <div className='flex flex-col gap-3'>
                        <div className='flex flex-col gap-2'>
                          <p className='text-agiLightGray text-base'>Competitors</p>
                          {company.research.competitors && company.research.competitors.length > 0 ? (
                            <div className='flex flex-wrap gap-2'>
                              {company.research.competitors.map((company: string, index: number) => (
                                <p key={index} className='text-white px-3 py-2 bg-agiGray rounded-md'>{company}</p>
                              ))}
                            </div>
                          ) : (
                            <p className='text-white mt-1'>No competitors available.</p>
                          )
                          }
                        </div>
                        <div className='flex flex-col gap-2'>
                          <p className='text-agiLightGray text-base'>Collaborators</p>
                          {company.research.collaborators && company.research.collaborators.length > 0 ? (
                            <div className='flex flex-wrap gap-2'>
                              {company.research.collaborators.map((company: string, index: number) => (
                                <p key={index} className='text-white px-3 py-2 bg-agiGray rounded-md'>{company}</p>
                              ))}
                            </div>
                          ) : (
                            <p className='text-white mt-1'>No collaborators available.</p>
                          )
                          }
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="mt-2 flex flex-col gap-8">
                    {/* <div>
                        <h3 className="text-lg font-semibold text-white">Overview</h3>
                        <div className='grid grid-cols-3 gap-3'>
                          <div className='bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6 mt-2'>
                            <p className='text-agiLightGray text-sm '>Year Founded</p>
                            {company.research.founded_year && (
                              <p className='text-white mt-1'>{company.research.founded_year}</p>
                            )}
                          </div>

                          <div className='bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6 mt-2'>
                            {company.research.hq && company.research.hq.length > 0 && (
                              <div>
                                <p className='text-agiLightGray text-sm'>HQ</p>
                                {company.research.hq.map((hq, index) => (
                                  <p key={index} className='text-white mt-1'>
                                    {hq.city}, {hq.country}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div> */}

                    {/* <div>
                        <h3 className="text-lg font-semibold text-white">Funding</h3>
                        <div className='grid grid-cols-2 gap-3'>
                          {company.research.funding_data && company.research.funding_data.length > 0 ? (
                            company.research.funding_data.map((fundraise, index) => (
                              <div key={index} className='bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6 mt-2'>
                                <p className='text-agiLightGray text-sm'>{fundraise.round}</p>
                                <p className='text-white mt-1 text-lg'>{fundraise.amount}</p>
                                {fundraise.investors && fundraise.investors.length > 0 && (
                                  <div className='mt-1'>
                                    {fundraise.investors.map((investor, idx) => (
                                      <p key={idx} className='text-agiLightGray'>{investor}</p>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <p className='text-agiLightGray col-span-2'>No funding data available.</p>
                          )}
                        </div>
                      </div> */}
                    {/* 
                      <div>
                        <h3 className="text-lg font-semibold text-white">Revenue</h3>
                        <div className='grid grid-cols-2 gap-3'>
                          {company.research.revenue_data_by_year && company.research.revenue_data_by_year.length > 0 ? (
                            company.research.revenue_data_by_year.map((revenueInfo, index) => (
                              <div key={index} className='bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6 mt-2'>
                                <p className='text-agiLightGray text-sm'>{revenueInfo.year}</p>
                                <p className='text-white mt-1'>{revenueInfo.revenue}</p>
                              </div>
                            ))
                          ) : (
                            <p className='text-agiLightGray col-span-2'>No revenue data available.</p>
                          )}
                        </div>
                      </div> */}

                    {/* {company.research.articles && company.research.articles.length > 0 && (
                        <div>
                          <h3 className="text-lg font-semibold text-white">Articles</h3>
                          <div className='flex gap-3 mt-2'>
                            {company.research.articles.map((article, index) => (
                              <a key={index} href={article.url} target="_blank" rel="noopener noreferrer">
                                <div className='bg-agiDarkGray border border-agiMediumGray hover:border-agiGray hover:cursor-pointer rounded-2xl p-6'>
                                  <p className='text-white'>{article.context}</p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      )} */}


                  </div>


                </div>
              )}


            </div>
          )}

          {activeTab === 'feed' && (
            <div>
              <p>Welcome to the feed.</p>
            </div>
          )}

          {activeTab === 'people' && (

            <div className="max-w-5xl">
              <div className='flex flex-col gap-3 bg-agiDarkGray border border-agiMediumGray rounded-2xl p-6'>
                <h3 className="text-xl font-semibold">Filter {company.employees.length} People</h3>
                <div className="flex gap-6">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={showCurrent}
                      onChange={() => setShowCurrent(!showCurrent)}
                      className=" h-5 w-5"
                    />
                    <label className="text-agiLightGray font-medium">Current</label>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={showPast}
                      onChange={() => setShowPast(!showPast)}
                      className=" h-5 w-5"
                    />
                    <label className="text-agiLightGray font-medium">Past</label>
                  </div>

                  {/* <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={showImportant}
                    onChange={() => setShowImportant(!showImportant)}
                    className="form-checkbox h-5 w-5 text-blue-600 bg-agiGray rounded"
                  />
                  <label className="text-agiLightGray font-medium">Leadership</label>
                </div> */}

                </div>
                {filteredEmployees.length > 0 ? (
                  <p className="text-white">{filteredEmployees.length} results.</p>
                ) : (
                  <p className="text-white">No results.</p>
                )}
              </div>


              {filteredEmployees.length > 0 && (


                <div className="grid grid-cols-2 gap-3 mt-4">
                  {filteredEmployees.map((employee, index) => (
                    <div
                      key={index}
                      onClick={() => onExtraSelect(employee.id, "Talent")}
                      className="bg-agiDarkGray border border-agiMediumGray hover:border-agiGray hover:cursor-pointer rounded-2xl text-sm p-4"
                    >
                      <div className='flex w-full justify-between'>

                        <p className="text-white text-lg">{employee.name}</p>
                        <div className='flex gap-2'>
                          {/* If important: */}
                          {/* <div className="w-2 h-2 bg-blue-500 rounded-full"></div> */}

                          {
                            employee.current ? (
                              <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                            ) :
                              (
                                <div className="w-2 h-2 bg-yellow-500 rounded-full"></div>
                              )
                          }
                        </div>

                      </div>


                      <p className="text-agiLightGray pt-1">{employee.headline}</p>
                    </div>

                  ))}
                </div>
              )}
            </div>
          )}

        </div>
      </div>
    </div>
  );
}
