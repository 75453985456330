import React, { useEffect, useState } from 'react';
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { ArrowBigUp, ArrowUpFromLineIcon } from 'lucide-react';
import ProjectCard from '../ProjectCard3';

export default function TalentProfile({ profile, onExtraSelect }) {
  const [info, setInfo] = useState([]);
  const [showOnlyAIGithub, setShowOnlyAIGithub] = useState(true);
  const [showOnlyAIExperiences, setShowOnlyAIExperiences] = useState(true);
  const handleSetInfo = (title, subtitle, desc, color?, extraInfo?) => {
    setInfo([title, subtitle, desc, color, extraInfo]);
  };

  const projectsCount = profile.user.github_projects ? profile.user.github_projects.length : 0;
  const aiProjectsCount = profile.user.github_projects ? profile.user.github_projects.filter(project => project.is_ai).length : 0;
  const experiencesCount = profile.user.experiences ? profile.user.experiences.length : 0;
  const aiExperiencesCount = profile.user.experiences ? profile.user.experiences.filter(exp => exp.is_ai).length : 0;

  useEffect(() => {
    if (aiProjectsCount === 0 && projectsCount > 0) {
      setShowOnlyAIGithub(false); // If there are no AI projects, show all projects
    }
  }, [aiProjectsCount, projectsCount]);

  useEffect(() => {
    if (aiExperiencesCount === 0 && experiencesCount > 0) {
      setShowOnlyAIExperiences(false); // If there are no AI projects, show all projects
    }
  }, [aiExperiencesCount, experiencesCount]);

  if (!profile) {
    return <div className=''>Loading...</div>;
  }

  const handleAIGithubToggle = () => {
    setShowOnlyAIGithub(prevState => !prevState); // Toggle the state
  };

  const handleAIExperiencesToggle = () => {
    setShowOnlyAIExperiences(prevState => !prevState); // Toggle the state
  };

  const filteredProjects = profile.user.github_projects
    ? (showOnlyAIGithub
      ? profile.user.github_projects.filter(project => project.is_ai)
      : profile.user.github_projects)
    : [];

  const filteredExperiences = profile.user.experiences
    ? (showOnlyAIExperiences
      ? profile.user.experiences.filter(experience => experience.is_ai)
      : profile.user.experiences)
    : [];

  const approvedEvents = profile.events ? profile.events.filter(event => event.status === 'approved').length : 0;
  const declinedEvents = profile.events ? profile.events.filter(event => event.status === 'declined').length : 0;
  const pendingEvents = profile.events ? profile.events.filter(event => event.status === 'pending').length : 0;

  // For question & answers.
  const detectAndRenderLinks = (text: string) => {
    const urlPattern = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const specificDomainPattern = /([a-zA-Z0-9-]+\.((ai|com|co|net|io|app)))(\/\S*)?/gi;

    return text.split(/(\s+)/).map((part, index) => {
      // First, attempt to match and render full URLs (with https, etc.)
      if (urlPattern.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline break-all">
            {part}
          </a>
        );
      }
      // Check for specific domains like .ai, .co, .net, .io, .app
      else if (specificDomainPattern.test(part)) {
        const match = part.match(specificDomainPattern);
        if (match) {
          const domain = match[0];
          // Replace domain in the part with a link
          const linkedText = part.replace(domain,
            `<a href="https://${domain}" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline break-all">${domain}</a>`
          );
          return (
            <span key={index} dangerouslySetInnerHTML={{ __html: linkedText }} />
          );
        }
      }
      // Render plain text if no match is found
      return <span key={index}>{part}</span>;
    });
  };




  return (
    <div key={profile.user.id} className='relative w-full h-full'>
      {info && info.length > 0 && (
        <div className='absolute w-full h-full flex justify-center p-4 pointer-events-none z-10 bg-[#00000070]'>
          <div className='gap-3 w-full h-fit p-6 flex flex-col justify-center border border-agiMediumGray rounded-lg bg-agiDarkGray text-agiLightGray max-w-4xl shadow-2xl'>
            <p className='text-white text-base'>
              {info[0]} <span className='text-agiLightGray'>- </span><span className={info[3] ? info[3] : 'text-agiLightGray'}>{info[1]}</span>
            </p>
            {info[4] && (
              <p>{info[4]}</p>
            )}
            <p className='text-lg'>{info[2]}</p>
          </div>
        </div>
      )}
      <div className="relative p-12 w-full h-full overflow-scroll z-0">
        <div className="mt-1 sm:absolute sm:right-12 gap-4 justify-center sm:items-end flex flex-col sm:pb-0 pb-8">
          <p className=' text-agiGray5 font-medium'>{profile.user.id}</p>
          {profile.user.email ? (
            <p className="font-medium text-agiGray5 -mt-2">{profile.user.email}</p>
          ) : (
            <p className="font-medium text-agiGray5 -mt-2">Email unmigrated.</p>
          )}
          {profile.user.linkedInURL && (
            <a className="flex items-center justify-center gap-2 text-agiLightGray hover:text-white group" href={profile.user.linkedInURL} target='_blank'>
              <p className="font-medium group-hover:underline"></p>
              <FaLinkedin className="text-xl" />
              <div className='flex justify-center items-center text-xs font-bold pl-1 pr-3 h-6 border-[1.5px] border-agiMediumGray text-agiGray5 rounded-md font-DMMono'>
                <ArrowBigUp className='h-4'></ArrowBigUp>1
              </div>
            </a>
          )}
          {profile.user.githubURL && (
            <a className="flex items-center justify-center gap-2 text-agiLightGray hover:text-white group" href={profile.user.githubURL} target='_blank'>
              <p className="text-md font-medium group-hover:underline"></p>
              <FaGithub className="text-xl" />
              <div className='flex justify-center items-center text-xs font-bold pl-1 pr-3 h-6 border-[1.5px] border-agiMediumGray text-agiGray5 rounded-md font-DMMono'>
                <ArrowBigUp className='h-4'></ArrowBigUp>2
              </div>
            </a>
          )}
          {profile.user.twitterURL && (
            <a className="flex items-center justify-center gap-2 text-agiLightGray hover:text-white group" href={profile.user.twitterURL} target='_blank'>
              <p className="text-md font-medium group-hover:underline"></p>
              <FaTwitter className="text-xl" />
              <div className='flex justify-center items-center text-xs font-bold pl-1 pr-3 h-6 border-[1.5px] border-agiMediumGray text-agiGray5 rounded-md font-DMMono'>
                <ArrowBigUp className='h-4'></ArrowBigUp>3
              </div>
            </a>
          )}
        </div>

        <div className='flex flex-col justify-start gap-8'>
          <div className='flex gap-8'>
            <img
              alt={profile.user.name}
              className="h-[84px] w-[84px] rounded-full object-cover border border-agiDarkGray"
              src={profile.user.photoURL || "/default-avatar.png"}
            ></img>
            <div className="">
              <h2 className="text-5xl font-bold mb-2">{profile.user.name}</h2>
              <p className='mb-2 text-agiLightGray mt-1 max-w-2xl'>{profile.user.headline}</p>
            </div>
          </div>

          <div className="max-w-5xl">
            <h3 className="text-lg font-semibold">Highlights</h3>
            <div className='pt-2 flex flex-col gap-3 text-agiLightGray'>
              {profile.user.experiences && (profile.user.experiences.length > 0) && (
                <p>
                  <span> {profile.user.experiences[0].title} @ {profile.user.experiences[0].company}</span>
                </p>
              )
                // :<p className='text-agiGray5'>No experiences.</p>
              }

              {profile.user.ai_project && (
                <span>AI Project: {profile.user.ai_project}</span>
              )}

              {profile.user.github_projects && profile.user.github_projects.length > 0 && (
                filteredProjects.length > 0 && (
                  filteredProjects.slice(0, 3).map((project, index) => (
                    <a
                      key={index}
                      href={project.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={() => handleSetInfo(`${project.name}`, "AI Github Project", project.description, 'text-blue-500', `★ ${project.stars}`)}
                      onMouseLeave={() => setInfo([])}
                      className='text-sm hover:text-blue-500 text-agiLightGray border px-3 pt-1 pb-1 rounded-2xl border-agiMediumGray'
                    >
                      <span className="mt-1 max-w-2xl">★ {project.stars} - {project.name.split('/')[1].replace(/-/g, ' ')}</span>
                    </a>
                  ))
                )
              )}

              <p>
                {approvedEvents} Approved<span className='text-agiGray5'> | </span>{pendingEvents} Pending<span className='text-agiGray5'> | </span>{declinedEvents} Declined<span className='text-agiGray5'> | </span>{profile.projects.length} {profile.projects.length === 1 ? 'Project' : 'Projects'}
              </p>
            </div>
          </div>



          {/* QUESTIONS FOR EVENT MANAGEMENT */}
          {profile.eventInfo && (profile.eventInfo.questions.length > 0) && (
            <div className="">
              <h3 className="text-lg font-semibold">Event Questions</h3>
              <div className='grid gap-2 grid-cols-3 mt-2'>
                {profile.eventInfo.questions.map((question, index) => (
                  <div key={index} className="border border-agiDarkGray px-6 py-4 rounded-2xl overflow-hidden break-words">
                    <p className="text-white mt-1 max-w-2xl text-sm">
                      {question}
                    </p>
                    {profile.eventInfo.answers && profile.eventInfo.answers.length > 0 && (
                      <p className="text-agiLightGray mt-1 max-w-2xl w-fit">
                        {detectAndRenderLinks(profile.eventInfo.answers[index])}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="w-full">
            <h3 className="text-lg font-semibold">Education</h3>
            {profile.user.education && (profile.user.education.length > 0) ? (
              <div className='grid grid-cols-3 gap-3 pt-2'>
                {profile.user.education.map((edu, index) => (
                  <div
                    key={index}
                    // href={profile.user.linkedInURL}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    className='hover:border-agiGray5 bg-transparent border border-agiMediumGray p-4 rounded-2xl flex justify-between items-start flex-col text-sm group'
                  >
                    <div>
                      <p className="text-white max-w-2xl text-base">{edu.school}
                        {edu.degree_name && (
                          <span className="text-agiLightGray mt-1 max-w-2xl text-sm"> - {edu.degree_name}</span>
                        )}
                      </p>
                    </div>
                    {edu.description && (
                      <p className="text-agiLightGray mt-1 max-w-2xl text-sm">
                        {edu.description.length > 200
                          ? `${edu.description.substring(0, 200)}...`
                          : edu.description}
                      </p>

                    )}

                  </div>

                ))}
              </div>
            ) : (
              <p className='text-agiGray5 mt-2'>No education.</p>
            )}
          </div>

          <div className="w-full">
            <h3 onClick={handleAIExperiencesToggle} className="text-lg font-semibold cursor-pointer">
              <span className='pr-2'>Experiences</span>
              <span className={showOnlyAIExperiences ? 'text-blue-500' : 'text-agiGray5'}>✦ </span>
              {experiencesCount > 0 && (
                <span className={`text-agiGray5 font-normal text-sm pl-1 ${showOnlyAIExperiences ? '' : 'hidden'}`}>
                  ( {experiencesCount - aiExperiencesCount}/{experiencesCount} Hidden )
                </span>
              )}
            </h3>
            {filteredExperiences && filteredExperiences.length > 0 ? (
              <div className='grid grid-cols-3 gap-3 pt-2'>
                {filteredExperiences.map((exp, index) => (
                  <div
                    key={index}
                    onClick={() => onExtraSelect(exp.company_id, "Company")}
                    className='hover:cursor-pointer hover:border-agiGray bg-transparent border border-agiMediumGray p-4 rounded-2xl flex justify-between items-start flex-row text-sm group'
                  >
                    <div>
                      <p className="text-white max-w-2xl break-words text-base">
                        {exp.is_ai && (
                          <span className=' text-blue-500 '>✦  </span>
                        )}
                        {exp.title}<span className='text-agiLightGray'> @ </span> {exp.company}
                      </p>
                      {/* <p className={"text-agiLightGray mt-1 max-w-2xl text-sm"}>{exp.company}</p> */}
                      {exp.description && (
                        <p className="text-agiLightGray mt-1 max-w-2xl text-sm">
                          {exp.description.length > 200
                            ? `${exp.description.substring(0, 200)}...`
                            : exp.description}
                        </p>

                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className='text-agiGray mt-2'>No experiences.</p>
            )}
          </div>


          <div className=''>
            <h3 onClick={handleAIGithubToggle} className="text-lg font-semibold cursor-pointer">
              <span className='pr-2'>Github Projects</span>
              <span className={showOnlyAIGithub ? 'text-blue-500' : 'text-agiGray5'}>✦ </span>
              {projectsCount > 0 && (
                <span className={`text-agiGray5 font-normal text-sm pl-1 ${showOnlyAIGithub ? '' : 'hidden'}`}>
                  ( {projectsCount - aiProjectsCount}/{projectsCount} Hidden )
                </span>
              )}
            </h3>
            {filteredProjects && filteredProjects.length > 0 ? (
              <div className='grid grid-cols-3 gap-3 pt-2'>
                {filteredProjects
                  .sort((a, b) => b.stars - a.stars) // Sort by stars in descending order
                  .map((project, index) => {
                    const formattedName = project.name.split('/')[1].replace(/-/g, ' ');
                    return (
                      <a
                        href={project.url}
                        target='_blank'
                        rel='noopener noreferrer'
                        key={index}
                        className='hover:cursor-pointer hover:border-agiGray relative bg-transparent border border-agiMediumGray p-4 rounded-2xl flex justify-start  flex-col group'
                      >
                        <div className='flex justify-between'>
                          <p className="text-white max-w-2xl break-words text-base">
                            {project.is_ai && (
                              <span
                                className=' text-blue-500 '
                              >✦  </span>
                            )}
                            {formattedName}
                          </p>
                          <p className='text-agiLightGray text-sm'>★ {project.stars}</p>
                        </div>

                        <p className="text-agiLightGray mt-1 max-w-2xl text-sm">{project.description}</p>
                      </a>
                    );
                  })}
              </div>
            ) : (
              <p className='text-agiGray5 mt-2'>No public personal Github projects.</p>
            )}
          </div>

          {/* Events section */}
          <div>
            <h3 className="text-lg font-semibold">Events</h3>
            {profile.events.length > 0 ? (
              <div className='grid grid-cols-3 gap-3 pt-2'>
                {profile.events.map((aEvent) => (
                  <a key={aEvent.id} href={`/events/${aEvent.slug}`} target="_blank" rel="noopener noreferrer">
                    <div className="hover:cursor-pointer relative rounded-2xl p-4 flex flex-col justify-between bg-transparent border-agiMediumGray hover:border-agiGray border h-full">
                      {aEvent.status && (
                        <span
                          className={`absolute top-1 left-1 text-white text-xs font-bold px-4 py-1 rounded-full z-50 ${aEvent.status === 'approved'
                            ? 'bg-plurple-gradient'
                            : aEvent.status === 'declined'
                              ? 'bg-red-500'
                              : 'bg-orellow-gradient'
                            }`}
                        >
                          {aEvent.status === 'approved' ? 'Approved' : aEvent.status === 'declined' ? 'Declined' : 'Pending'}
                        </span>
                      )}

                      <div className={'mt-6'}>
                        <h3 className="text-sm font-bold text-gray-50">{aEvent.name}</h3>
                        <p className="text-sm text-agiLightGray pt-2">{aEvent.date}</p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <p className='text-agiGray5 mt-2'>No events attended.</p>
            )}
          </div>

          {/* Projects section */}
          <div>
            <h3 className="text-lg font-semibold">Hackathon Projects</h3>
            {profile.projects.length > 0 ? (
              <div className="mt-2 grid grid-cols-3 gap-3">
                {profile.projects.map((project, index) => (
                  <ProjectCard
                    key={index}
                    project={project}
                    isOwner={false}
                    showEventLabel={true}
                  />
                ))}
              </div>
            ) : (
              <p className='text-agiGray5 mt-2'>No hackathon projects.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
