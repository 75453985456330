import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Button } from './ui/button';
import { CalendarIcon } from "@radix-ui/react-icons";
import EventManagement from './admin/EventManagement';
import { LocateIcon } from "lucide-react";
import ProjectCard from "./ProjectCard3";
import { isUpcomingEvent } from "src/lib/utils";
import ApprovedApplicationCard from "./ApprovedApplicationCard";
import { Textarea } from "./ui/textarea";
import { fetchEventByIdOrSlug, Event, createApplication, removeApplication } from '../services/eventsService';
import CreateEventDialog from './admin/CreateEventDialog';
import { SimpleUser, updateUserInfo } from '../services/usersService';
import userEvent from "@testing-library/user-event";
import { useAuth } from "../AuthContext";
import { useReward } from 'react-rewards';

interface EventPageProps {
  slug: string;
}

const EventPage: React.FC<EventPageProps> = ({ slug }) => {
  const { setUser, user } = useAuth();


  const [event, setEvent] = useState<Event>(null);
  const [status, setStatus] = useState(null);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isManageDialogOpen, setIsManageDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [answers, setAnswers] = useState<string[]>([]);
  const [headlineAnswer, setHeadlineAnswer] = useState('');
  const [pastProjectAnswer, setPastProjectAnswer] = useState('');

  const [isQuestionDialogOpen, setIsQuestionDialogOpen] = useState(false);

  const [theUser, setTheUser] = useState<SimpleUser | null>(user);

  const { reward, isAnimating } = useReward('rewardId', 'emoji', {
    emoji: ['🤖', '🔥', 'AGI'],
    lifetime: 300,
    elementSize: 30,
    elementCount: 25,
    startVelocity: 60,
    spread: 80,
    zIndex: 9999,
  });


  useEffect(() => {
    if (slug) {
      const user_id = theUser?.id || "";
      const loadEvent = async () => {
        const fetchedEvent = await fetchEventByIdOrSlug(slug, user_id);
        // console.log('Fetched Event:', fetchedEvent.response);
        setEvent(fetchedEvent.response.event);
        if (fetchedEvent.response.projects) {
          const fetchedProjects = fetchedEvent.response.projects;
          const projectsWithWeight = fetchedProjects.filter(p => p.awardWeight && p.awardWeight > 0);
          const projectsWithoutWeight = fetchedProjects.filter(p => !p.awardWeight || p.awardWeight === 0);
          projectsWithWeight.sort((a, b) => a.awardWeight - b.awardWeight);
          const sortedProjects = [...projectsWithWeight, ...projectsWithoutWeight];
          setProjects(sortedProjects);
        }
        if (user_id && fetchedEvent.response.event.status) {
          setStatus(fetchedEvent.response.event.status);
        }
      };
      loadEvent();

      if (user_id && theUser?.role === 'admin') {
        setIsAdminUser(true);
      }
    }
  }, [slug, isEditDialogOpen]);

  useEffect(() => {
    if (theUser?.role && event) {
      const userRole = theUser.role;
      const access = event.access || 'public';
      if ((access === 'member' && userRole === 'unknown') || (access === 'admin' && userRole !== 'admin')) {
        console.log("invalid access. Event access:", access, "Your Access:", userRole);
        navigate('/events');
      }
    }
  }, [theUser, event]);

  useEffect(() => {
    if (event && event.description.includes('<blockquote class="twitter-tweet">')) {
      // Load Twitter script after component mounts
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://platform.twitter.com/widgets.js';
      script.charset = 'utf-8';
      document.body.appendChild(script);

      // Cleanup script tag if necessary
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [event]);

  const handleRequest = async () => {
    const user_id = theUser?.id;
    if (!user_id) {
      alert('You need to be logged in to request to join.');
      window.localStorage.setItem('redirectUrl', `/events/${slug}`);
      navigate('/login');
      return;
    }

    if (!theUser.name || !theUser.headline || !theUser.linkedInURL) {
      alert('You need to complete your profile to request to join.');
      window.localStorage.setItem('redirectUrl', `/events/${slug}`);
      navigate('/profile');
      return;
    }

    if ((event.requireDefaultQuestions) || (event.questions && event.questions.length > 0)) {
      setIsQuestionDialogOpen(true);
      setAnswers(Array(event.questions.length).fill(''));
      setHeadlineAnswer(theUser.headline);
      setPastProjectAnswer(theUser.ai_project);
    } else {
      handleRegister();
    }
  };

  const attemptRegister = async () => {
    let allQuestionsAnswered = true;

    event.questions.forEach((question, index) => {
      if (question.endsWith('*') && (!answers[index] || answers[index].trim().length === 0)) {
        allQuestionsAnswered = false;
      }
    });

    if (event.requireDefaultQuestions) {
      if (!headlineAnswer || headlineAnswer.trim().length === 0) {
        allQuestionsAnswered = false;
      }
      if (!pastProjectAnswer || pastProjectAnswer.trim().length === 0) {
        allQuestionsAnswered = false;
      }
    }

    if (allQuestionsAnswered) {
      console.log('Submitting answers:', answers);
      setIsQuestionDialogOpen(false);
      await handleRegister();
    } else {
      alert('Please answer all required questions (marked with an astrix*) before submitting.');
    }
  };

  const handleRegister = async () => {
    const user_id = theUser?.id;
    const event_id = event.id;
    await createApplication(user_id, event_id, answers, event.questions);

    if (event.requireDefaultQuestions) {
      // Update theUser with the new ai_project and headline values
      const updatedUser = {
        ...theUser,
        ai_project: pastProjectAnswer,
        headline: headlineAnswer,
      };
      await updateUserInfo(updatedUser); // Pass the updated user object
      setUser(updatedUser); // Auth context one
      setTheUser(updatedUser); // Local state one
    }

    setStatus('pending');
    reward();
    // setTimeout(reward, 100);
    // setTimeout(reward, 200);
  };


  const handleCancel = async () => {
    const user_id = theUser?.id;
    const event_id = event.id;
    await removeApplication(user_id, event_id);
    setStatus(null);
    setIsCancelDialogOpen(false);
  };

  if (!event) {
    return <div className="text-white w-full h-full flex justify-center items-center">Loading...</div>;
  }

  const isUpcoming = isUpcomingEvent(event.timestamp);

  const handleClose = () => {
    setIsEditDialogOpen(false);
  };


  return (
    <div className="flex min-h-screen flex-col justify-center items-center">
      {event.imageSrc && (
        <div className="font-DMMono relative bg-agiDarkGray rounded-2xl overflow-hidden mx-4 max-h-[400px] md:max-h-[600px] max-w-6xl lg:mt-12">
          <img
            alt={event.name}
            className="max-h-[400px] md:max-h-[600px]"
            src={event.imageSrc}
          />
        </div>
      )}
      <div className="text-white font-DMMono absolute -bottom-[10px]">
        <span id="rewardId"></span>
      </div>
      <main className="flex-1 mb-64 w-full">
        <section className="bg-agiBlack">
          <div className="mx-4">
            <div className="mx-auto max-w-3xl">
              <div className="mt-6 lg:mt-12">
                <h1 className="text-2xl md:text-4xl font-bold text-gray-50 md:text-left text-center">{event.name}</h1>
                <div className="pt-4">
                  {isAdminUser && (
                    <div className="flex justify-center my-4 pb-4 mb:pb-0 md:justify-start flex-wrap gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setIsManageDialogOpen(true)}
                      >
                        Quick approval
                      </Button>
                      <Link to={`/admin/events/${slug}`}>
                        <Button
                          variant="outline"
                          size="sm"
                        >
                          Manage Event Page
                        </Button>
                      </Link>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setIsEditDialogOpen(true)}
                      >
                        Edit Event
                      </Button>
                      <CreateEventDialog
                        isOpen={isEditDialogOpen}
                        onClose={handleClose}
                        event={event}
                      />
                    </div>
                  )}
                  <div className="mt-4 flex md:flex-row flex-col md:items-start gap-6 items-center">
                    <div className="flex items-center justify-center gap-2 text-agiLightGray flex-row">
                      <CalendarIcon className="h-4 w-4 flex-col md:flex-row" />
                      <span className="text-sm">{event.date}</span>
                    </div>
                    {status === 'approved' ? (
                      <div className="flex items-center gap-2 text-agiLightGray flex-col md:flex-row">
                        <LocateIcon className="h-4 w-4" />
                        <span className="text-sm md:text-left text-center md:px-0 px-8">{event.location}</span>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center gap-2 text-agiLightGray flex-row">
                        <LocateIcon className="h-4 w-4" />
                        <span className="text-sm">Register to see the location</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="md:mt-12 mt-8">
                <div className="text-white border border-agiDarkGray rounded-2xl p-12">
                  {!isUpcoming ? (
                    <div className="flex md:justify-between md:items-center items-center md:flex-row flex-col">
                      <div className="flex flex-col md:items-start items-center">
                        <p className="text-xl font-semibold text-white">This event is over.</p>
                        <p className="text-md pt-3 md:text-left text-center text-agiLightGray">We hope to see you at the next event!</p>
                      </div>
                      <Link to={`/events/`}>
                        <Button onClick={() => navigate('/events')} className="transition-all rounded-full bg-white text-agiBlack hover:scale-105 md:mt-0 mt-4">
                          Explore Upcoming Events
                        </Button>
                      </Link>
                    </div>
                  ) : status !== null ? (
                    status === 'approved' ? (
                      <div className="flex md:justify-between md:items-center items-center md:flex-row flex-col">
                        <div className="flex flex-col md:items-start items-center">
                          <p className="text-xl font-semibold text-white">You're going!</p>
                          <p className="text-md pt-3 md:text-left text-center text-agiLightGray">You have been approved to attend this event.</p>
                        </div>
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => setIsCancelDialogOpen(true)}
                          className="md:mt-0 mt-4"
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div className="flex md:justify-between md:items-center items-center md:flex-row flex-col">
                        <div className="flex flex-col md:items-start items-center font-DMSans">
                          <p className="text-xl font-semibold text-white">Approval pending.</p>
                          <p className="text-md pt-3 md:text-left text-center text-agiLightGray">Thank you for your interest. Your request is under review.</p>
                        </div>
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => setIsCancelDialogOpen(true)}
                          className="md:mt-0 mt-4 font-DMSans"
                        >
                          Cancel Request
                        </Button>
                      </div>
                    )
                  ) : (
                    <div className="flex md:justify-between md:items-center items-center md:flex-row flex-col">
                      <div className="flex flex-col md:items-start items-center">
                        <p className="text-xl font-semibold text-white">Approval required.</p>
                        <p className="text-md pt-3 md:text-left text-center text-agiLightGray">Your registration is subject to approval by the host.</p>
                      </div>
                      <Button onClick={handleRequest} className="transition-all rounded-full bg-white text-agiBlack hover:scale-105 md:mt-0 mt-4">
                        Request to Attend
                      </Button>
                    </div>
                  )}
                </div>
                {status === 'approved' && (
                  <div className="pt-6">
                    <ApprovedApplicationCard
                      date={event.date}
                      eventName={event.name}
                      profileName={theUser?.name}
                      profileHeadline={theUser?.headline}
                    />
                  </div>
                )}
              </div>
              <div className="mt-12 text-agiLightGray">
                <h2 className="text-2xl font-bold text-gray-50 pb-4">Description</h2>
                <div dangerouslySetInnerHTML={{ __html: event.description.replace(/\n/g, '<br/>') }}></div>
              </div>
              {event.speakers_updated && event.speakers_updated.length > 0 && (
                <div className="mt-6">
                  <h2 className="text-2xl font-bold text-gray-50 py-4">Speakers</h2>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {event.speakers_updated.map((speaker, index) => (
                      <InfoCard key={index} name={speaker.name} title={speaker.title} isAvatar />
                    ))}
                  </div>
                </div>
              )}
              {event.judges_updated && event.judges_updated.length > 0 && (
                <div className="mt-6">
                  <h2 className="text-2xl font-bold text-gray-50 py-4">Judges</h2>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {event.judges_updated.map((judge, index) => (
                      <InfoCard key={index} name={judge.name} title={judge.title} isAvatar />
                    ))}
                  </div>
                </div>
              )}
              {event.sponsors && event.sponsors.length > 0 && (
                <div className="mt-6">
                  <h2 className="text-2xl font-bold text-gray-50 py-4">Sponsors</h2>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {event.sponsors.map((sponsor, index) => (
                      <SponsorCard
                        key={index}
                        name={sponsor.name}
                        imgURL={sponsor.imageSrc || null}
                        websiteURL={sponsor.sponsorURL || null}
                      />
                    ))}
                  </div>
                </div>
              )}

              {event.cohosts && event.cohosts.length > 0 && (
                <div className="mt-6">
                  <h2 className="text-2xl font-bold text-gray-50 py-4">Cohosts</h2>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {event.cohosts.map((cohost, index) => (
                      <SponsorCard
                        key={index}
                        name={cohost.name}
                        imgURL={cohost.imageSrc || null}
                        websiteURL={cohost.cohostURL || null}
                      />
                    ))}
                  </div>
                </div>
              )}

              {projects && projects.length > 0 && (
                <div className="mt-6">
                  <h2 className="text-2xl font-bold text-white py-4">Projects</h2>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                    {projects.map((project, index) => (
                      <ProjectCard
                        key={index}
                        project={project}
                        isOwner={false}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      {isAdminUser && (
        <Dialog open={isManageDialogOpen} onOpenChange={setIsManageDialogOpen}>
          <DialogContent className="max-w-[1380px] w-full max-h-[90vh] overflow-y-auto">
            <EventManagement slug={slug} passedUser={theUser} />
          </DialogContent>
        </Dialog>
      )}

      <Dialog open={isCancelDialogOpen} onOpenChange={setIsCancelDialogOpen}>
        <DialogContent className="max-w-md w-full">
          <DialogHeader>
            <DialogTitle>Cancel Registration</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <p>Are you sure you want to cancel your registration?</p>
            <div className="flex justify-end gap-4">
              <Button variant="outline" onClick={() => setIsCancelDialogOpen(false)}>
                No
              </Button>
              <Button variant="destructive" onClick={handleCancel}>
                Yes, Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={isQuestionDialogOpen} onOpenChange={setIsQuestionDialogOpen}>
        <DialogContent className="md:max-w-2xl">
          <DialogHeader>
            <DialogTitle>Event Questions</DialogTitle>
          </DialogHeader>

          <div className="flex flex-col gap-8 md:mb-0 mb-[36rem]">
            <p className="text-agiLightGray text-left leading-loose">Please answer the following questions.<br />
              These answers are used to ensure quality of attendees.
            </p>   
            {event.requireDefaultQuestions && (
              <div className="flex flex-col gap-8">
                <div key="headline" className="space-y-2">
                  <p className="font-semibold text-sm text-agiLightGray">
                  Headline (CS PhD @ SAIL/BAIR or founder/dev @ link.ai)<span className="text-white"> *</span>
                  </p>
                  <Textarea
                    className="w-full text-[16px]"
                    value={headlineAnswer}
                    onChange={(e) => {
                      setHeadlineAnswer(e.target.value);
                    }}
                  />
                </div>
                <div key="aiProject" className="space-y-2">
                  <p className="font-semibold text-sm text-agiLightGray">
                  Example AI project you’ve built (2 sentence max eg. text to video app with PyTorch demo @ url)<span className="text-white"> *</span>
                  </p>
                  <Textarea
                    className="w-full text-[16px]"
                    value={pastProjectAnswer}
                    onChange={(e) => {
                      setPastProjectAnswer(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col gap-8">

              {event.questions && event.questions.length > 0 && (
                event.questions.map((question, index) => (
                  <div key={index} className="space-y-2">
                    <p className="font-semibold text-sm text-agiLightGray">
                      {question.endsWith('*') ? (
                        <>
                          {question.slice(0, -1)}
                          <span className="text-white"> *</span>
                        </>
                      ) : (
                        question
                      )}
                    </p>
                    <Textarea
                      className="w-full text-[16px]"
                      value={answers[index] || ''}
                      onChange={(e) => {
                        const newAnswers = [...answers];
                        newAnswers[index] = e.target.value;
                        setAnswers(newAnswers);
                      }}
                    />
                  </div>
                ))
              )}
            </div>


            <div className="flex justify-center gap-4 flex-col-reverse md:flex-row">

              <Button
                className="w-full"
                variant="outline"
                onClick={() => {
                  const confirmed = window.confirm("Are you sure you want to cancel?");
                  if (confirmed) {
                    setIsQuestionDialogOpen(false);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className="w-full"
                variant="white"
                onClick={attemptRegister}
              >
                Submit & Request To Attend
              </Button>


            </div>
          </div>
        </DialogContent>
      </Dialog>



    </div>
  );
};

const InfoCard = ({ name, isAvatar, title }) => {
  return (
    <div className="flex flex-col items-center justify-center rounded-2xl border border-agiMediumGray bg-none bg-gray-gradient p-4 py-9 gap-4">
      {isAvatar && (
        <Avatar className="h-12 w-12 rounded-full">
          <AvatarImage src="/avatars/04.png" />
          <AvatarFallback>{name.charAt(0)}</AvatarFallback>
        </Avatar>
      )
      }
      <div className="flex flex-col justify-center items-center gap-2 px-2">
        <h3 className="text-lg font-semibold text-white text-center">{name}</h3>
        {title && (
          <h3 className="text-base font-semibold text-agiLightGray font-DMSans break-word text-center">{title}</h3>
        )}

      </div>
    </div>
  );
};

const SponsorCard = ({ name, imgURL, websiteURL }) => {
  // Trim and normalize the website URL to include https:// if it's missing
  const trimmedURL = websiteURL ? websiteURL.trim() : null;

  const normalizedURL = trimmedURL 
    ? trimmedURL.startsWith('http://') || trimmedURL.startsWith('https://')
      ? trimmedURL
      : `https://${trimmedURL}`
    : null;

  const CardContent = (
    <div className={`flex flex-col items-center justify-center rounded-2xl border border-agiMediumGray bg-none bg-gray-gradient p-4 py-9 gap-4 ${normalizedURL ? 'hover:border-agiGray' : ''}`}>
      {imgURL ? (
        <img
          src={imgURL}
          alt={name}
          className="h-8 w-32 mx-auto object-contain"
        />
      ) : (
        <div className="">
          <h3 className="text-lg font-semibold text-white font-DMSans">{name}</h3>
        </div>
      )}
    </div>
  );

  return normalizedURL ? (
    <a href={normalizedURL} target="_blank" rel="noopener noreferrer">
      {CardContent}
    </a>
  ) : (
    CardContent
  );
};




export default EventPage;
