import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";

import { Button } from '../ui/button';
import React from 'react';

interface ThankYouDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const ThankYouDialog: React.FC<ThankYouDialogProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[400px] dark:shadow-neon">
        <DialogHeader>
          <DialogTitle>Thank You!</DialogTitle>
        </DialogHeader>
        <div className="p-4">
          <p>Thank you for reporting the issue. We will look into it shortly.</p>
        </div>
        <DialogFooter>
          <Button type="button" onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ThankYouDialog;
