import React, { ReactNode } from 'react';
import { SimpleUser } from '../../services/usersService';

import Footer from './Footer';
import NaviBar from './NaviBar';

interface LayoutProps {
  children: ReactNode;
  theUser: SimpleUser;
}

const Layout: React.FC<LayoutProps> = ({ children, theUser }) => {
  return (
    <div className="flex min-h-screen flex-col bg-agiBlack">
      <NaviBar theUser={theUser} />
      <main className="flex-1">
        {children}
      </main>
      <Footer passedUser={theUser }/>
    </div>
  );
};

export default Layout;
