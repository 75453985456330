import React, { FC, useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useMatch, useNavigate, useParams } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import ConfirmSignInPage from './components/ConfirmSignInPage';
import EmailLinkLoginPage from './components/EmailLinkLoginPage';
import EventPage from './components/Event';
import EventManagement from './components/admin/EventManagement';
import Events from './components/Events';
import Layout from './components/common/Layout';
import Profile from './pages/Profile';
import TalentSearch from './components/admin/TalentSearch';
import { SimpleUser } from './services/usersService';
import { auth } from './lib/firebaseConfig';

const App: React.FC = () => {
  const { user, loading } = useAuth();

  const navigate = useNavigate();

  const matchProfile = useMatch('/profile');

  const logoutFirebase = async () => {
    if (auth.currentuser) {
      await auth.signOut();
    }
  }

  useEffect(() => {
    if (matchProfile && (!user || user === null)) {
      logoutFirebase(); // log out firebase
      navigate('/login');
    }
  }, [user]);

  if (loading) {
    return <div className='flex justify-center bg-agiBlack text-white items-center font-DMMono w-screen h-screen'>
      Loading...
    </div>
  }

  return (
    <div className='font-DMSans'>
        <Layout theUser={user}>
          <Routes>
            <Route path="/" element={user ? <Navigate to="/profile" /> : <EmailLinkLoginPage />} />
            <Route path="/login" element={user ? <Navigate to="/profile" /> : <EmailLinkLoginPage />} />
            <Route path="/confirm" element={<ConfirmSignInPage />} />
            <Route path="/events" element={<Events passedUser={user} />} />
            <Route path="/events/:slug" element={<EventWrapper />} />
            <Route path="/profile" element={<Profile/>} />

            {/* ADMIN PAGES */}
            <Route path="/admin" element={user?.role === 'admin' ? <TalentSearch /> : <Navigate to="/events" replace />} />
            <Route path="/admin/events/:slug" element={user?.role === 'admin' ? <EventManagementWrapper passedUser={user} /> : <Navigate to="/events" replace />} />
          </Routes>
        </Layout>
    </div>
  );
};

const EventWrapper: React.FC = () => {
  const { slug } = useParams();
  return <EventPage slug={slug} />;
};

const EventManagementWrapper: React.FC<{ passedUser: SimpleUser | null }> = ({ passedUser }) => {
  const { slug } = useParams();
  return <EventManagement slug={slug} passedUser={passedUser} />;
};

const AppWrapper: React.FC = () => (
  <AuthProvider>
     <Router>
    <App />
    </Router>
  </AuthProvider>
);

export default AppWrapper;
