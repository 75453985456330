import axios from 'axios';
import { getActiveToken } from './authService';

import { API_URL } from './apiService';
const UPDATE_USER = 'user/update';

interface Education {
  school: string;
  degree_name: string;
  field_of_study: string;
  starts_at: { day: number; month: number; year: number };
  ends_at: { day: number; month: number; year: number };
  school_id: string;
}

interface Experience {
  company: string;
  title: string;
  description: string;
  starts_at: { day: number; month: number; year: number };
  ends_at: { day: number; month: number; year: number };
  company_id: string;
  is_ai: boolean; // UNSURE IF IN NEW SCHEMA.
}

interface GitHubProject {
  name: string;
  owner: string;
  stars: number;
  description: string;
  tags: string[];
  projectURL: string;
  is_ai: boolean;
}

interface Event {
  event_id: string;
  project_id: string;
  status: string;
}

export interface User {
  id: string;
  name: string;
  name_lower: string;
  email: string;
  role: string;
  headline: string;
  ai_project: string;
  photoURL: string;
  twitterURL: string;
  linkedInURL: string;
  githubURL: string;
  education: Education[];
  experiences: Experience[];
  github_projects: GitHubProject[];
  events: Event[];
  summary: string;
  enriched_date: string;
}

export interface SimpleUser {
  id: string;
  email: string;
  name: string;
  name_lower: string;
  role: string;
  headline: string;
  ai_project: string;
  photoURL: string;
  twitterURL: string;
  linkedInURL: string;
  githubURL: string;
}

export interface SimpleUserData {
  response: SimpleUser;
}

interface UpdateData {
  user: User;
}

export const fetchSimpleUserById = async (user_id: string): Promise<SimpleUserData> => {
  // console.log('fetchSimpleUserById:', user_id);
  const idToken = await getActiveToken();
  try {
    const response = await axios.get<SimpleUserData>(`${API_URL}user/${user_id}/simple`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`fetchSimpleUserById failed: ${error}`);
  }
};

export const updateUserInfo = async (updated_user: SimpleUser): Promise<void> => {
  // console.log('updateUserInfo:', updated_user);
  const idToken = await getActiveToken();
  try {
    const response = await axios.post<string>(`${API_URL}${UPDATE_USER}`, updated_user, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
  } catch (error) {
    throw new Error(`updateUserInfo failed: ${error}`);
  }
}

export const createUser = async (new_user: SimpleUser): Promise<void> => {
  // console.log('createUser:', new_user);
  const idToken = await getActiveToken();
  try {
    const response = await axios.post<string>(`${API_URL}user/new`, new_user, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
    });
  } catch (error) {
    throw new Error(`createUser failed: ${error}`);
  }
}
